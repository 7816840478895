import React, { useEffect, useState } from 'react';
import { ApolloProvider } from 'react-apollo';
import { useLocation } from 'react-router';

import { useEditor } from '@toasttab/sites-components';
import { Organization } from 'schema-dts';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import { DiningBehavior, LogoSizeOption } from 'src/apollo/sites';
import useTracker from 'src/lib/js/hooks/useTracker';
import SchemaThing from 'src/public/components/seo/SchemaThing';
import { getImageUrl } from 'src/shared/components/common/Image';

import LocationMap from 'shared/components/common/location_map/LocationMap';
import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';
import { RestaurantSiteContent, useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import NoMatch404 from 'shared/components/no_match_404/NoMatch404';
import { ScreenWidth, useIsMobile } from 'shared/js/utils/WindowContext';

import PageMeta from 'public/components/default_template/meta/PageMeta';
import Nav from 'public/components/default_template/nav/Nav';
import { CartContextProvider } from 'public/components/online_ordering/CartContext';
import { DeliveryContextProvider } from 'public/components/online_ordering/DeliveryContext';
import { FulfillmentContextProvider } from 'public/components/online_ordering/FulfillmentContext';
import ToastProduct from 'public/components/online_ordering/ToastProduct';
import { ListViewIcon, MapViewIcon } from 'public/components/pages/location_selection_page/LocationSelectionIcons';

import LocationPickerWithMap, { BasicLocationPicker, MAP_SOURCE_ID, View } from './LocationSelection';

const getDiningOptionBehavior = (behavior: DiningBehavior | undefined | null, defaultOpt: DiningOptionBehavior) => {
  switch(behavior) {
    case DiningBehavior.Delivery:
      return DiningOptionBehavior.Delivery;
    case DiningBehavior.Takeout:
      return DiningOptionBehavior.TakeOut;
    default:
      return defaultOpt;
  }
};

type Props = {
  queryParams: URLSearchParams
}

export const CoreLocationSelectionPage = ({ queryParams }: Props) => {
  const { restaurant: siteRestaurant, toastProduct } = useRestaurant();
  const tracker = useTracker();
  const showFullPicker = toastProduct === ToastProduct.Sites || toastProduct === ToastProduct.OOPro;
  const [view, setView] = useState<View>(View.List);
  const isMobile = useIsMobile(ScreenWidth.SMALL);
  const { meta, content, config } = siteRestaurant;

  const paramDiningOption = queryParams?.get('diningOption') as DiningBehavior | null;
  const [selectedDiningOptionBehavior, setSelectedDiningOptionBehavior] = useState(getDiningOptionBehavior(paramDiningOption || config.ooConfig?.defaultDiningBehavior, DiningOptionBehavior.TakeOut));

  useEffect(() => {
    if(typeof window !== 'undefined') {
      tracker.track('Location picker page view', {
        managementGuid: siteRestaurant.externalId,
        picker: showFullPicker ? 'Location picker with map' : 'Basic location picker'
      });
    }
  }, [siteRestaurant.externalId, tracker, showFullPicker]);

  if(!content) {
    return <NoMatch404 meta={meta} />;
  }

  const { primaryCta, nonPrimaryCtas } = content as RestaurantSiteContent;
  const title = `${siteRestaurant.config.ooConfig?.seoMeta?.title || siteRestaurant.name} | Find a location`;
  const description = siteRestaurant.config.ooConfig?.seoMeta?.description || `Select a location from ${siteRestaurant.name} and order online. Get the best prices and service by ordering direct!`;

  return (
    <>
      <PageMeta title={title} description={description} />
      <div className="defaultTemplate">
        <Nav
          withShadow
          navType="stickyNav"
          logoSrc={meta.icon}
          logoSize={LogoSizeOption.Standard}
          primaryCta={primaryCta}
          nonPrimaryCtas={nonPrimaryCtas} />
        {showFullPicker ?
          <main className="locationSelectionPageContent">
            <div className="leftColumn">
              <LocationPickerWithMap diningOptionBehavior={selectedDiningOptionBehavior} updateDiningBehavior={setSelectedDiningOptionBehavior} view={view} allowMap={Boolean(isMobile)} />
              {isMobile &&
                <button className="viewButton" onClick={() => setView(view === View.Map ? View.List : View.Map)}>
                  {view === View.Map ? <ListViewIcon color={meta.primaryColor} /> : <MapViewIcon color={meta.primaryColor} /> }
                  <span>{`Show ${view === View.Map ? 'list' : 'map'}`}</span>
                </button>}
            </div>
            {!isMobile &&
              <div className="rightColumn">
                <LocationMap hideDefaultLocation sourceId={MAP_SOURCE_ID} markerStyle="locationDetails" selectedDiningOption={selectedDiningOptionBehavior} />
              </div>}
          </main> :
          <main className="basicLocationSelectionPageContent">
            <BasicLocationPicker diningOptionBehavior={selectedDiningOptionBehavior} updateDiningBehavior={setSelectedDiningOptionBehavior} view={View.List} allowMap={false} />
          </main>}
      </div>
      <SchemaThing<Organization> json={routes => ({
        '@context': 'https://schema.org',
        '@type': 'Organization',
        '@id': routes.homeUrl,
        url: routes.homeUrl,
        name: siteRestaurant.name,
        brand: siteRestaurant.name,
        sameAs: [routes.homeUrl],
        ...meta.icon ? { logo: getImageUrl(meta.icon) } : {},
        subOrganization: siteRestaurant?.locations?.map(loc => ({
          '@type': 'Restaurant',
          '@id': routes.orderUrl(loc.externalId),
          sameAs: routes.orderUrl(loc.externalId),
          name: loc.name,
          servesCuisine: meta.cuisine,
          paymentAccepted: meta.acceptedPaymentTypes,
          priceRange: meta.priceRange,
          telephone: loc.phoneNumber,
          address: {
            '@type': 'PostalAddress',
            addressCountry: loc.country || undefined,
            postalCode: loc.zipcode,
            addressRegion: loc.state,
            addressLocality: loc.city,
            streetAddress: loc.address2 ? `${loc.address1}, ${loc.address2}` : loc.address1
          },
          image: siteRestaurant.meta.icon ? getImageUrl(siteRestaurant.meta.icon) : undefined
        }))
      })} />
    </>
  );
};


const LocationSelectionPageWithRouterDependencies = () => {
  const location = useLocation();
  return <CoreLocationSelectionPage queryParams={new URLSearchParams(location.search)} />;
};

const WrappedLocationSelectionPage = () => {
  const { isEditor } = useEditor();
  const editorQueryParams = new URLSearchParams(`diningOption=${DiningOptionBehavior.TakeOut}`);
  return isEditor
    ? <CoreLocationSelectionPage queryParams={editorQueryParams} /> :
    <LocationSelectionPageWithRouterDependencies />;
};

const LocationSelectionPage = React.memo(WrappedLocationSelectionPage);

const ContextualLocationSelectionPage = () => {
  const ooClient = useOOClient();

  return (
    <ApolloProvider client={ooClient}>
      <CartContextProvider>
        <DeliveryContextProvider>
          <FulfillmentContextProvider>
            <LocationSelectionPage />
          </FulfillmentContextProvider>
        </DeliveryContextProvider>
      </CartContextProvider>
    </ApolloProvider>
  );
};

export default ContextualLocationSelectionPage;
