import React, { useCallback, useEffect, useMemo } from 'react';

import classnames from 'classnames';

import {
  LoyaltyRedemption,
  Cart, RewardTypeData
} from 'src/apollo/onlineOrdering';
import useTracker from 'src/lib/js/hooks/useTracker';
import { useCustomer } from 'src/public/components/online_ordering/CustomerContextCommon';
import { useLoyalty } from 'src/public/components/online_ordering/LoyaltyContext';

import Image from 'shared/components/common/Image';
import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import CheckoutSection from 'public/components/default_template/online_ordering/checkout/CheckoutSection';
import { LockedLoyaltyRedemption } from 'public/components/default_template/online_ordering/checkout/loyalty/LockedLoyaltyRedemption';
import { useCart } from 'public/components/online_ordering/CartContext';

import { getFirstOrThirdParty, isRedemptionInCart } from './LoyaltyUtils';
import { ThirdPartyLoyaltyGuestConsentForm } from './ThirdPartyLoyaltyGuestConsentForm';

type RedemptionDescriptionProps = {
  redemption: LoyaltyRedemption;
  applied: boolean;
};

export const RedemptionDescription = ({ redemption, applied }: RedemptionDescriptionProps) => {
  const imageSrc = useMemo(() => {
    return applied ? 'icons/diamond-rewards-white.svg' : 'icons/diamond-rewards.svg';
  }, [applied]);

  const messages = redemption.currentState?.messages;
  if(!messages || !messages.rewardValueMessage) {
    return <></>;
  }
  const { rewardValueMessage, rewardLabelMessage } = messages;

  // 3rd party doesn't support rewardLabelMessage
  const description = rewardLabelMessage === null
    ? rewardValueMessage
    : `${rewardValueMessage} (${rewardLabelMessage})`;

  return (
    <div data-testid="loyalty-redemption-description">
      <Image alt="Loyalty reward" src={imageSrc} data-testid="loyalty-icon" />
      {description}
    </div>
  );
};

const LoyaltyRedemptionSection = () => {
  const { ooRestaurant } = useRestaurant();
  const { cart, refetchCart } = useCart();
  const {
    hasLoyaltyAccount,
    redemptions,
    currentState,
    removeRedemption,
    addRedemption,
    loadingRedemptions,
    loadingLoyaltyAccount,
    redemptionErrorRef,
    setAllowLookup
  } = useLoyalty();
  const tracker = useTracker();
  const { customer } = useCustomer();

  const requireLookupAuthorization = useMemo(() => getFirstOrThirdParty(ooRestaurant?.loyaltyConfig?.programName) === '3PL', [ooRestaurant?.loyaltyConfig?.programName]);

  useEffect(() => {
    // auto allow 1st party
    if(getFirstOrThirdParty(ooRestaurant?.loyaltyConfig?.programName) === 'ToastLoyalty') {
      setAllowLookup(true, false);
    }
  }, [ooRestaurant?.loyaltyConfig?.programName, setAllowLookup]);

  const onClickRedemption = useCallback((redemption: LoyaltyRedemption) => async () => {
    let action = 'Removed';
    if(isRedemptionInCart(redemption, cart as Cart)) {
      await removeRedemption(redemption);
    } else {
      action = 'Added';
      await addRedemption(redemption);
    }
    tracker.track('Loyalty Reward Tapped', {
      redemptionName: redemption.name,
      action: action,
      firstOrThirdParty: getFirstOrThirdParty(ooRestaurant?.loyaltyConfig?.programName),
      error: redemptionErrorRef?.current
    });
    refetchCart();
  }, [cart, tracker, ooRestaurant?.loyaltyConfig?.programName, redemptionErrorRef, refetchCart, removeRedemption, addRedemption]);

  const availableRedemptions = useMemo(() => {
    return redemptions.filter(it => !!it.available);
  }, [redemptions]);

  const nonAvailableRedemptions = useMemo(() => {
    return redemptions.filter(it => !it.available && it.referenceId !== 'LOYALTY_POINTS_AMOUNT' && it.rewardType != RewardTypeData.Birthday);
  }, [redemptions]);

  if(requireLookupAuthorization && customer && !hasLoyaltyAccount) return (
    <ThirdPartyLoyaltyGuestConsentForm restaurantName={ ooRestaurant?.name } />
  );

  if(!ooRestaurant?.loyaltyConfig || !cart || !hasLoyaltyAccount || !customer) {
    return null;
  }

  return (
    <CheckoutSection title="Rewards">
      <div className="loyaltyRedemption" data-testid="loyalty-redemption">
        {loadingLoyaltyAccount && <LoadingSpinnerOverlay />}
        <div className="header">{currentState?.messages?.balance}</div>
        { availableRedemptions.length !== 0 ?
          <>
            <div className="message">Select a reward to redeem it with this order</div>
            <br />
            <div className="redemptions" data-testid="redemptions-list">
              {loadingRedemptions && <LoadingSpinnerOverlay />}
              {availableRedemptions.map((r, i) => {
                const appliedToCart = isRedemptionInCart(r, cart as Cart);
                return (
                  <button
                    key={`redemption-${r.redemptionGuid}-${r.referenceId}`}
                    type="button"
                    className={classnames('redemption', { added: appliedToCart, loading: loadingRedemptions })}
                    onClick={onClickRedemption(r)}
                    data-testid={`redemption-button-${i}`}>
                    <RedemptionDescription redemption={r} applied={appliedToCart} />
                  </button>);
              })}
            </div>
          </>
          :
          <>
            <div>
              {nonAvailableRedemptions.map(redemption => {
                return (
                  <LockedLoyaltyRedemption
                    key={`locked-redemption-${redemption.redemptionGuid}-${redemption.referenceId}`}
                    redemption={redemption} />
                );
              })}
            </div>
          </>}
        {redemptionErrorRef?.current && <p className="error">{redemptionErrorRef.current}</p>}
      </div>
      <div className="separator top" />
    </CheckoutSection>
  );
};

export default LoyaltyRedemptionSection;
