import { useEffect, useRef, useState } from 'react';

import * as Sentry from '@sentry/react';

export const useComponentTimeout = (componentName: string, timeoutSecs: number, restaurantName?: string) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const timeoutKey = useRef<ReturnType<typeof setTimeout> | null>(null);
  // Ref needed to access the current state value within setTimeout

  useEffect(() => {
    // There is a bit of a race here since we potentially load multiple
    // components within this timeoutSecs window but that is rare to get an overlap and
    // we only really care about the last one to be requested.
    if(timeoutKey.current) {
      clearTimeout(timeoutKey.current);
      timeoutKey.current = null;
    }

    if(!isLoaded) {
      // alert if component takes more than timeoutSecs to load
      timeoutKey.current = setTimeout(() => {
        Sentry.captureMessage(`${componentName} did not load within timeout`, {
          level: 'info',
          extra: { timeoutSecs, restaurantName }
        });
      }, timeoutSecs * 1000);
    }

    // Clean up timeout on unmount
    return () => {
      if(timeoutKey.current) {
        clearTimeout(timeoutKey.current);
        timeoutKey.current = null;
      }
    };
    // We only want this to rerun when isLoaded changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  return { setIsLoaded };
};

export default useComponentTimeout;
