import React, { useMemo } from 'react';

import { _div, _heading } from '@toasttab/sites-components';

import { Block as BlockType } from 'src/apollo/sites';
import { EditableButton } from 'src/shared/components/common/button';
import { ScreenWidth, useScreenWidth } from 'src/shared/js/utils/WindowContext';

import { _Image } from 'shared/components/common/editable_image/EditableImage';
import { EditableShape } from 'shared/components/common/shape/Shape';

import { NUM_COLS } from './DynamicSection';


type Props = {
  block: BlockType;
  editPath: string;
}

export const BlockWrapper = ({ children, block, mobileOffset, numRows }: React.PropsWithChildren<{ block: BlockType; mobileOffset: number; numRows: number }>) => {
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < ScreenWidth.SMALL;

  // Computes the grid area for this block. Most of the logic here is only used
  // on mobile if the block has no set mobile layout
  const gridArea = useMemo(() => {
    if(!isMobile) {
      return `${block.startY}/${block.startX}/${block.endY}/${block.endX}`;
    }

    if(
      block.mobileStartX &&
        block.mobileEndX &&
        block.mobileStartY &&
        block.mobileEndY
    ) {
      return `${block.mobileStartY}/${block.mobileStartX}/${block.mobileEndY}/${block.mobileEndX}`;
    }

    // Whether the block exists only in the right half on desktop
    // and so should reflow to the bottom half on mobile
    const shouldReflow = block.startX > NUM_COLS / 2;

    // Whether the block crosses the desktop midline and so should be collapsed just
    // to the top half on mobile
    const shouldCollapse = block.startX <= NUM_COLS / 2 && block.endX - 1 > NUM_COLS / 2;

    const startX = shouldReflow
      ? block.startX - NUM_COLS / 2
      : shouldCollapse
        ? Math.ceil(block.startX / 2)
        : block.startX;
    const startY =
      (shouldReflow ? block.startY + numRows : block.startY) - mobileOffset;
    const endX = shouldReflow
      ? block.endX - NUM_COLS / 2
      : shouldCollapse
        ? NUM_COLS / 2 - Math.ceil((NUM_COLS - block.endX) / 2) + 1
        : block.endX;
    const endY =
      (shouldReflow ? block.endY + numRows : block.endY) - mobileOffset;

    return `${startY}/${startX}/${endY}/${endX}`;
  }, [isMobile, block, mobileOffset, numRows]);

  return <div data-testid="blockWrapper" style={{ gridArea, zIndex: block.layer, display: 'flex' }}>{children}</div>;
};

export const Block = ({ block, editPath }: Props) => {
  switch(block.kind) {
    case 'paragraph':
    case 'text':
      return <_div editPath={editPath} html={typeof block.contents === 'string' ? block.contents : JSON.stringify(block.contents)} hideOutline />;
    case 'heading1':
      return <_heading editPath={editPath} styleLevel={1} html={typeof block.contents === 'string' ? block.contents : JSON.stringify(block.contents)} hideOutline />;
    case 'heading2':
      return <_heading editPath={editPath} styleLevel={2} html={typeof block.contents === 'string' ? block.contents : JSON.stringify(block.contents)} hideOutline />;
    case 'heading3':
      return <_heading editPath={editPath} styleLevel={3} html={typeof block.contents === 'string' ? block.contents : JSON.stringify(block.contents)} hideOutline />;
    case 'heading4':
      return <_heading editPath={editPath} styleLevel={4} html={typeof block.contents === 'string' ? block.contents : JSON.stringify(block.contents)} hideOutline />;
    case 'image':
      return <_Image style={{ height: '100%', width: '100%' }} imageObjectPath={editPath} imageObject={block.contents} decorated={true} />;
    case 'button':
      return <EditableButton editPath={editPath} {...block.contents} />;
    case 'shape':
      return <EditableShape editPath={editPath} {...block.contents} />;
    default:
      return null;
  }
};
