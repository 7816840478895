import React from 'react';
import { withRouter } from 'react-router';

import { RequestContextProps } from 'src/lib/js/context';
import useScrollToTopOnNewLocation from 'src/lib/js/hooks/useScrollToTopOnNewLocation';
import useTracker from 'src/lib/js/hooks/useTracker';
import { getHost } from 'src/lib/js/utilities';
import { StructuredDataProvider } from 'src/public/components/seo/context';
import { ExperimentContextProvider } from 'src/shared/components/common/ab_testing/ABTestContext';

import AttributionContextProvider from 'shared/components/common/attribution_context/AttributionContext';
import RestaurantContextProvider, { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { RestaurantRoutesContextProvider } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';
import { RxDataUsage } from 'shared/js/hooks/useDefaultSiteData';

import Meta from 'public/components/default_template/meta/Meta';
import Popups from 'public/components/default_template/popups/Popups';
import { SpotlightContextProvider } from 'public/components/default_template/spotlight/SpotlightContext';

type WrappedProps = RequestContextProps & {
  titleTag?: string;
  descriptionTag?: string;
  homePath?: string;
  orderPath?: string;
  orderPathPattern?: string;
  checkoutPathPrefix?: string;
  confirmPathPrefix?: string;
  noPopups?: boolean;
};

type Props = WrappedProps & {
  ooUsage?: RxDataUsage;
  sitesUsage?: RxDataUsage;
};

const WrappedPageShell = (props: React.PropsWithChildren<WrappedProps>) => {
  useScrollToTopOnNewLocation();
  const {
    restaurant: { meta, pixels, content },
    selectedLocation: { meta: locationMeta, shortUrl }
  } = useRestaurant();
  return (
    <RestaurantRoutesContextProvider
      shortUrl={shortUrl}
      homePath={props.homePath}
      orderPath={props.orderPath}
      orderPathPattern={props.orderPathPattern}
      checkoutPathPrefix={props.checkoutPathPrefix}
      confirmPathPrefix={props.confirmPathPrefix}>
      <StructuredDataProvider>
        <Meta
          {...meta}
          {...locationMeta}
          primaryColor={meta.primaryColor || locationMeta.primaryColor}
          titleTag={props.titleTag}
          description={props.descriptionTag}
          domain={getHost(props.staticContext)}
          pixels={pixels}
          externalModules={content?.externalModules} />
        {!props.noPopups ? <Popups /> : null}
        {props.children}
      </StructuredDataProvider>
    </RestaurantRoutesContextProvider>
  );
};

const ContextualPageShell = ({ ooUsage, sitesUsage, children, ...rest }: React.PropsWithChildren<Props>) => {
  const tracker = useTracker();

  return (
    <ExperimentContextProvider
      onVariantSelected={(experimentName, variantName) => {
        tracker.register({ [experimentName]: variantName });
      }}>
      <SpotlightContextProvider>
        <AttributionContextProvider>
          <RestaurantContextProvider ooUsage={ooUsage} sitesUsage={sitesUsage} isEditor={false}>
            <WrappedPageShell {...rest}>{children}</WrappedPageShell>
          </RestaurantContextProvider>
        </AttributionContextProvider>
      </SpotlightContextProvider>
    </ExperimentContextProvider>
  );
};
export default withRouter<Props, React.ComponentType<Props>>(ContextualPageShell);
