import React, { useMemo } from 'react';

import { FieldType, useEditor } from '@toasttab/sites-components';

import { Block as BlockType, PaddingEnum } from 'src/apollo/sites';
import { getBackgroundColorModule, getSectionPaddingModule } from 'src/shared/components/common/editor_context/editableUtils';
import { ScreenWidth, useScreenWidth } from 'src/shared/js/utils/WindowContext';

import { Block, BlockWrapper } from './Block';

export const NUM_COLS = 24;

type Props = {
  blocks: BlockType[];
  numRows: number;
  mobileNumRows?: number | null;
  editPath: string;
  padding: PaddingEnum;
  backgroundColor?: string | null;
}

export const DynamicSection = ({ blocks, editPath, numRows, mobileNumRows, padding, backgroundColor }: Props) => {
  const { isEditor, dynamicModuleWrapper: DynamicModuleWrapper, useEditableRef } = useEditor();
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < ScreenWidth.SMALL;

  const { computedMobileNumRows, leftMobileOffset, rightMobileOffset } =
      useMemo(() => {
        if(mobileNumRows) {
          // User has manually set this value, so we don't need any extra info. Return immediately.
          return {
            computedMobileNumRows: mobileNumRows,
            leftMobileOffset: 0,
            rightMobileOffset: 0
          };
        }

        // Computes the adjusted default row count for mobile using a couple of rules:
        // 1. If nothing will reflow into the top half, remove it. Same with the bottom half.
        // 2. Then, compute the offsets from the top and bottom and clamp height to the first and last elements by Y coordinate
        // 3. Finally, compress everything together by finding the offset between the last top and first bottom elements and collapsing them

        // We use these to determine if we can drop a half on reflow.
        // We only need to keep a half on reflow if there's an item that occupies it -- otherwise we can remove it from our row count
        // and offset all blocks accordingly
        const hasSecondHalf = blocks.some(
          block => block.startX > NUM_COLS / 2
        );
        const hasFirstHalf = blocks.some(block => block.startX <= NUM_COLS / 2);

        // This is the offset from the top of the module where the highest (closest to the top) block starts on mobile
        const topOffset = blocks.length
          ? blocks
            .filter(block => !hasFirstHalf || block.startX <= NUM_COLS / 2)
            .reduce(
              (offset, block) =>
                offset ? Math.min(offset, block.startY) : block.startY,
              undefined as number | undefined
            ) || 0
          : 0;

        // This is the offset from the bottom of the module where the lowest (closest to the bottom) block ends on mobile
        const bottomOffset = blocks.length
          ? blocks
            .filter(block => !hasSecondHalf || block.startX > NUM_COLS / 2)
            .reduce(
              (offset, block) =>
                offset
                  ? Math.min(offset, numRows - block.endY)
                  : numRows - block.endY,
              undefined as number | undefined
            ) || 0
          : numRows;

        let computedNumRows = numRows * 2;
        let rightMobileOffset = topOffset;
        if(!hasFirstHalf) {
          computedNumRows /= 2;
          rightMobileOffset += computedNumRows;
        } else if(!hasSecondHalf) {
          computedNumRows /= 2;
        }

        computedNumRows -= topOffset;
        computedNumRows -= bottomOffset;

        // If there is content in both halves, remove the gap between them
        if(hasFirstHalf && hasSecondHalf) {
          const middleDifference =
            (blocks
              .filter(block => block.startX > NUM_COLS / 2)
              .reduce(
                (offset, block) =>
                  offset
                    ? Math.min(offset, numRows + block.startY)
                    : numRows + block.startY,
                undefined as number | undefined
              ) || numRows) -
            (blocks
              .filter(block => block.startX <= NUM_COLS / 2)
              .reduce(
                (offset, block) =>
                  offset ? Math.min(offset, block.endY) : block.endY,
                undefined as number | undefined
              ) || numRows);

          computedNumRows -= middleDifference;
          rightMobileOffset += middleDifference;
        }

        return {
          computedMobileNumRows: computedNumRows,
          leftMobileOffset: topOffset - 1,
          rightMobileOffset: rightMobileOffset - 1
        };
      }, [blocks, mobileNumRows, numRows]);

  const { editableRef } = useEditableRef({
    name: 'dynamic section',
    actions: ['delete'],
    displayName: 'Section',
    path: editPath,
    schema: {
      fields: [
        getSectionPaddingModule(editPath, padding),
        getBackgroundColorModule(editPath, backgroundColor),
        {
          displayName: 'Rows (default view)',
          type: FieldType.Number,
          path: `${editPath}.numRows`,
          value: numRows,
          validation: { min: 8, max: 20, step: 1 }
        },
        {
          displayName: 'Rows (mobile view)',
          type: FieldType.Number,
          path: `${editPath}.mobileNumRows`,
          value: computedMobileNumRows,
          validation: { min: 1, max: 20, step: 1 }
        }
      ]
    }
  });

  if(isEditor && DynamicModuleWrapper) {
    return <DynamicModuleWrapper ref={editableRef} blockComponent={Block} blocks={blocks} numRows={numRows} mobileNumRows={mobileNumRows} editPath={editPath} />;
  }

  return (
    <div style={{ width: '100%', display: 'flex' }} data-testid="dynamicSection">
      <div
        style={{
          flex: 1,
          display: 'grid',
          gap: '8px',
          gridTemplateColumns: !isMobile
            ? `repeat(${NUM_COLS}, 1fr)`
            : `repeat(${NUM_COLS / 2}, 1fr)`,
          gridTemplateRows: !isMobile
            ? `repeat(${numRows}, 45px)`
            : `repeat(${computedMobileNumRows}, 24px)`,
          position: 'relative'
        }}>
        {blocks.map((block, index) =>
          <BlockWrapper
            key={block.guid}
            block={block}
            mobileOffset={block.startX <= NUM_COLS / 2 ? leftMobileOffset : rightMobileOffset}
            numRows={isMobile ? numRows : computedMobileNumRows}>
            <Block block={block} editPath={`${editPath}.blocks[${index}]`} />
          </BlockWrapper>)}
      </div>
    </div>
  );
};
