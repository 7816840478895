import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { AnimatePresence, motion } from 'framer-motion';
import { MenuItem } from 'schema-dts';

// eslint-disable-next-line camelcase
import { DiningOptionBehavior, MenuApiType, MenuItemFromCartQuery, Menus_VisibilityEnum, useDoMenuItemQuery, useMenuItemDetailsQuery, useMenuItemFromCartLazyQuery } from 'src/apollo/onlineOrdering';
import { reportError } from 'src/lib/js/clientError';
import { I18nType } from 'src/lib/js/hooks/useFormatPrice';
import { useThrottledTracker } from 'src/lib/js/hooks/useTracker';
import { formatImageURL } from 'src/lib/js/utilities';
import SchemaThing from 'src/public/components/seo/SchemaThing';
import FormattedPrice from 'src/shared/components/common/price/FormattedPrice';

import Image from 'shared/components/common/Image';
import { useAlertModalContext } from 'shared/components/common/alert_modal/AlertModal';
import ErrorNotice from 'shared/components/common/error_notice';
import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import { Modal, ModalCloseButton, ModalContent, ModalOverlay, useModalContext } from 'shared/components/common/modal';
import { usePopoverContext } from 'shared/components/common/popover/PopoverContext';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { REQUEST_FAILURE_MESSAGE } from 'shared/js/constants';
import { ScreenWidth, useIsMobile } from 'shared/js/utils/WindowContext';

import { CART_POPOVER_CONTEXT_KEY } from 'public/components/default_template/online_ordering/cart/CartPopover';
import DiningOptionsModal from 'public/components/default_template/online_ordering/dining_options/DiningOptionsModal';
import QuantitySelector from 'public/components/default_template/online_ordering/item_modal/QuantitySelector';
import SelectionList from 'public/components/default_template/online_ordering/item_modal/SelectionList';
import MenuItemTag from 'public/components/default_template/online_ordering/item_tags/MenuItemTag';
import { useMenuSearchContext, SearchHighlightedText } from 'public/components/default_template/search';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useFulfillment } from 'public/components/online_ordering/FulfillmentContext';
import { ModifierContextProvider, useModifierContext } from 'public/components/online_ordering/ModifierContext';
import { ItemWithTimeBasedRule, useTimeBasedRules } from 'public/components/online_ordering/TimeBasedRuleContext';
import { Action, collapseModifier, collapseModifierGroups, ModifierGroup, SelectionModifierGroup, WrappedModifier } from 'public/components/online_ordering/types';

type Props = {
  restaurantGuid?: string;
  shortUrl?: string;
  itemGuid?: string | null;
  itemGroupGuid?: string | null;
  onClose: () => void;
  isOpen: boolean;
  specialRequestsEnabled?: boolean | null;
  specialRequestsPlaceholder?: string | null;
  selectionGuid?: string | null;
  cartGuid?: string;
  withTransitions?: boolean;
  shouldShowHighlights?: boolean;
};

type BodyProps = {
  name?: string | null;
  itemGuid?: string | null;
  basePrice?: number | null;
  groups?: (ModifierGroup | SelectionModifierGroup)[] | null;
  specialRequestsEnabled?: boolean | null;
  specialRequestsPlaceholder?: string | null;
  specialRequestsContent?: string | null;
  description?: string | null;
  image?: string;
  loading?: boolean;
  shouldShowHighlights?: boolean;
}

type ActionsProps = {
  loading?: boolean;
  selectionGuid?: string | null;
  itemGuid?: string | null;
}

const headerId = 'menu-item-modal-header';

const ModalActions = ({ loading, selectionGuid }: ActionsProps) => {
  const { track } = useThrottledTracker();
  const context = usePopoverContext(CART_POPOVER_CONTEXT_KEY);
  const { displayedModifier, commitModifier, price, quantity, setQuantity, validate } = useModifierContext();
  const { orderingDisabled } = useRestaurant();
  const { addToCart, editCartItem, loadingCart } = useCart();
  const { timeBasedRulesMap, verifyNoConflictingItemsInCart, verifyItemIsFulfillable } = useTimeBasedRules();
  const { fulfillmentData, guestSelectedFulfillmentTime, selectedDiningOptionBehavior, diningOptionBehaviorAvailability } = useFulfillment();
  const { onClose } = useModalContext();
  const { openAlertModal } = useAlertModalContext();
  const isMobile = useIsMobile(ScreenWidth.SMALL);
  const isDisabled = loading || !quantity || loadingCart;
  const { searchString, canUseSearch } = useMenuSearchContext();
  const selectedDiningBehavior = useMemo(() => selectedDiningOptionBehavior === DiningOptionBehavior.Delivery ? 'delivery' : 'pickup', [selectedDiningOptionBehavior]);
  const scheduledOrdersOnly = useMemo(() => {
    const behavior = diningOptionBehaviorAvailability[selectedDiningOptionBehavior === DiningOptionBehavior.Delivery ? 'delivery' : 'takeout'];
    return behavior?.scheduledAvailable && !behavior.asapAvailable;
  }, [diningOptionBehaviorAvailability, selectedDiningOptionBehavior]);
  const promptFulfillmentTimeUpdate = scheduledOrdersOnly && !guestSelectedFulfillmentTime;

  const submitModifier = useCallback(() => {
    if(displayedModifier?.isRoot || !validate()) {
      return;
    }

    commitModifier();
  }, [commitModifier, validate, displayedModifier]);

  const onSubmit = useCallback(async () => {
    if(!displayedModifier?.isRoot) {
      return;
    }

    if(!validate()) {
      return;
    }

    let errorType = null;
    if(selectionGuid) {
      try {
        errorType = await editCartItem(displayedModifier, quantity, selectionGuid);
      } catch(error) {
        openAlertModal(REQUEST_FAILURE_MESSAGE);
        reportError('Error editing cart item.', error);
      }
    } else {
      const timeBasedRules = timeBasedRulesMap[displayedModifier.modifier.itemGuid];
      try {
        const item = {
          itemGuid: displayedModifier.modifier.itemGuid,
          preorderRule: timeBasedRules?.preorderRule,
          leadTimeRule: timeBasedRules?.leadTimeRule,
          name: displayedModifier.name,
          modifier: displayedModifier,
          quantity
        } as ItemWithTimeBasedRule;
        if(!verifyNoConflictingItemsInCart(item)) {
          onClose();
          return;
        }
        errorType = await addToCart(displayedModifier, quantity, fulfillmentData?.cartFulfillmentData);
      } catch(error) {
        openAlertModal(REQUEST_FAILURE_MESSAGE);
        reportError('Error adding cart item.', error);
        errorType = 'CartModificationError';
      }

      track('Added item to cart', {
        quantity,
        price,
        unitOfMeasure: displayedModifier.unitOfMeasure,
        timeBasedRule: timeBasedRules?.leadTimeRule ? 'Minimum lead time' : timeBasedRules?.preorderRule ? 'Preorder' : null,
        canUseSearch,
        searchString: canUseSearch && searchString ? searchString : null
      });
    }
    if(errorType !== 'CartModificationError') {
      onClose();
      //only open the cart modal for desktop
      if(!isMobile || selectionGuid) {
        context?.open();
      }
    }
  }, [
    track,
    selectionGuid,
    editCartItem,
    displayedModifier,
    quantity,
    price,
    addToCart,
    onClose,
    context,
    validate,
    fulfillmentData,
    openAlertModal,
    timeBasedRulesMap,
    verifyNoConflictingItemsInCart,
    canUseSearch,
    searchString,
    isMobile
  ]);

  if(orderingDisabled) {
    return (
      <button type="button" disabled className="modalButton flush">
        Currently not accepting orders
      </button>
    );
  }

  const fulfillmentType = fulfillmentData?.cartFulfillmentData.fulfillmentType;
  const futureSchedules = fulfillmentData?.scheduleData.futureScheduleDates;
  if(displayedModifier?.isRoot && fulfillmentType && !verifyItemIsFulfillable(displayedModifier.modifier.itemGuid, fulfillmentType, futureSchedules ?? [])) {
    return (
      <button type="button" disabled className="modalButton flush">
        This item is not currently available
      </button>
    );
  }

  return (
    displayedModifier?.isRoot && !orderingDisabled ?
      <div className="addToCart">
        {!displayedModifier.usesFractionalQuantity ?
          <div className="actionWrapper">
            <QuantitySelector min={1} canIncrease={true} defaultValue={quantity} onQuantityChange={setQuantity} />
          </div>
          : null}
        {promptFulfillmentTimeUpdate ?
          <DiningOptionsModal>
            {openModal =>
              <div className="actionWrapper primary">
                <button type="button" className="modalButton centered" onClick={openModal} data-testid="menu-item-cart-cta">
                  <div>{`Update ${selectedDiningBehavior} time`}</div>
                </button>
              </div>}
          </DiningOptionsModal> :
          <div className="actionWrapper primary">
            <button type="button" className="modalButton" disabled={isDisabled} onClick={onSubmit} data-testid="menu-item-cart-cta">
              <div>{selectionGuid ? 'Update Cart' : 'Add to Cart' }</div>
              {!loading && <div>{isNaN(quantity) ? '0.00' : <FormattedPrice value={quantity * price} />}</div>}
            </button>
          </div>}
      </div> :
      <button type="button" className="modalButton flush"
        onClick={displayedModifier?.isRoot ? onClose : submitModifier}
        data-testid="modifier-item-continue-cta">
                Continue
      </button>
  );
};

const WrappedModalBody = (props: BodyProps, ref: React.RefObject<HTMLDivElement>) => {
  const {
    groups,
    image,
    loading,
    name,
    basePrice,
    itemGuid,
    description,
    specialRequestsPlaceholder, specialRequestsEnabled, specialRequestsContent,
    shouldShowHighlights
  } = props;
  const {
    displayedModifier,
    modifiers,
    dropModifier,
    addSpecialRequests,
    quantity,
    setQuantity,
    deselect
  } = useModifierContext();
  const { error } = useCart();
  const { restaurant: { meta: { backgroundColor } }, ooRestaurant } = useRestaurant();
  const { validateItemFulfillmentTime, timeBasedRulesMap } = useTimeBasedRules();
  const fulfillmentTimeValid = validateItemFulfillmentTime(itemGuid);
  const timeBasedRules = itemGuid && timeBasedRulesMap[itemGuid];
  const [prevGuid, setPrevGuid] = useState<string | null>(null);
  const [wasPrev, setWasPrev] = useState(false);
  const restaurantI18n: I18nType = ooRestaurant?.i18n as I18nType;

  const isSelected = modifiers.length > 1 && displayedModifier?.groupGuid
        && !!modifiers[modifiers.length - 2]?.modifierGroups[displayedModifier?.groupGuid]?.[displayedModifier.modifier.itemGuid];
  const previousModifier = modifiers.length > 1 ? modifiers[modifiers.length - 2] : null;

  useEffect(() => {
    // Determine if the modifier that's about to be displayed was already on the stack.
    // If it was, we don't want to animate its entrance
    if(previousModifier) {
      setWasPrev(prevGuid === displayedModifier?.modifier.itemGuid);
      setPrevGuid(previousModifier.modifier.itemGuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousModifier, displayedModifier]);

  const schema = useMemo(() => {
    if(name) {
      return (
        <SchemaThing<MenuItem> json={routes => ({
          '@context': 'https://schema.org',
          '@type': 'MenuItem',
          '@id': routes.location,
          name: name,
          ...description ? { description: description } : {},
          image: image ? formatImageURL(image) : undefined,
          ...ooRestaurant?.guid
            ? {
              subjectOf: {
                '@type': 'Menu',
                '@id': routes.orderUrl(ooRestaurant.guid)
              }
            }
            : {},
          identifier: itemGuid ?? undefined,
          offers: basePrice
            ? {
              '@type': 'OfferForPurchase',
              priceCurrency: restaurantI18n.currency,
              price: basePrice
            }
            : undefined
        })} />
      );
    }
    return null;
  }, [name, description, image, itemGuid, restaurantI18n, ooRestaurant?.guid, basePrice]);

  const getDisplay = useCallback((modifier: WrappedModifier | null, shouldEnter: boolean, shouldExit) => {
    const nameForDisplay = shouldShowHighlights && name
      ? <SearchHighlightedText text={name} />
      : <Skeleton />;


    const descriptionForDisplay = shouldShowHighlights && description
      ? <SearchHighlightedText text={description} />
      : description;
    return (
    // Force showing the root modifier if we're SSRing
      modifier && !modifier?.isRoot && typeof window !== 'undefined' ?
        <motion.div
          key={modifier.modifier.itemGuid}
          initial={shouldEnter ? { marginLeft: '100%' } : false}
          animate={{ marginLeft: '0%' }}
          exit={shouldExit ? { marginLeft: '100%' } : undefined}
          transition={{ duration: 0.5 }}
          className="secondaryContent"
          ref={ref}>
          <div className="header secondary">
            <ModalCloseButton className="itemModalCloseButton" />
            <div className="header-row">
              <div className="backButton" onClick={dropModifier}>
                <Image alt="Back button" src="/icons/caret-left.svg" eagerLoad />
              </div>
              <h2 className="modalTitle" id={headerId}>{nameForDisplay}</h2>
            </div>
          </div>
          <div className="paddedContent">
            <p className="subTitle">{modifier.modifier.name}</p>
            {isSelected ?
              <button type="button" className="modalButton marginBottom" onClick={() => {
                // Order is important here: first we disregard any current selections, then we
                // remove the modifier from the parent modifier group
                dropModifier();
                deselect(displayedModifier.groupGuid, displayedModifier.modifier.itemGuid);
              }}>
                Remove
              </button>
              : null}
            <div className="modifierGroups" role="list">
              {modifier.modifier.modifierGroups.map((group: ModifierGroup) => <SelectionList
                key={group.guid} group={group} />)}
            </div>
          </div>
        </motion.div> :
        <div className="content" ref={ref}>
          <ModalCloseButton className="itemModalCloseButton" />
          {modifier && modifier?.isRoot || (image || loading) ?
            image || loading ?
              <div className="imageWrapper">
                {image ?
                  <Image src={formatImageURL(image)} className="itemModalImage" eagerLoad />
                  : null}
              </div>
              : null
            : null}
          {timeBasedRules && <MenuItemTag timeBasedRules={timeBasedRules} showMessage={!fulfillmentTimeValid} mini={false} backgroundColor={backgroundColor} />}
          <div className="header">
            <div className="header-row">
              {modifier && !modifier?.isRoot ?
                <div className="backButton" onClick={dropModifier}>
                  <Image alt="Back button" src="/icons/caret-left.svg" eagerLoad />
                </div>
                : null}
              <h2 className="modalTitle" id={headerId}>{nameForDisplay}</h2>
            </div>
          </div>
          <div className="paddedContent">
            {error?.kind === 'CartModificationError' ?
              <div className="description"><ErrorNotice>{error.message}</ErrorNotice></div>
              : null}
            <p className="description">{loading ? <Skeleton width="100%" /> : descriptionForDisplay}</p>
            <div className="modifierGroups" role="list">
              {groups
                ? groups.map((group: ModifierGroup) => <SelectionList key={group.guid} group={group} />)
                : <SelectionList />}
            </div>
            {specialRequestsEnabled ?
              <div className="specialRequests modifierGroup">
                <label htmlFor="items-modal-special-request" className="title">Special Instructions</label>
                <div className="row textarea">
                  <textarea
                    id="items-modal-special-request"
                    onChange={e => {
                      addSpecialRequests(e.target.value);
                    }}
                    placeholder={specialRequestsPlaceholder || undefined}
                    aria-placeholder={specialRequestsPlaceholder || undefined}
                    maxLength={200}
                    defaultValue={specialRequestsContent || undefined}
                    rows={4} />
                </div>
              </div>
              : null}
            {modifier?.usesFractionalQuantity ?
              <div className="fractionalQuantity modifierGroup">
                <div className="title">Quantity</div>
                <div className="quantity">
                  <input
                    type="number"
                    inputMode="decimal"
                    name="fractionalQuantityInput"
                    placeholder="0.00"
                    defaultValue={quantity.toFixed(2)}
                    onChange={e => setQuantity(parseFloat(e.target.value))} />
                  <label
                    htmlFor="fractionalQuantityInput">{modifier.unitOfMeasure?.toLowerCase()}
                  </label>
                </div>
                <i>{modifier.price}/{modifier.unitOfMeasure?.toLowerCase()}</i>
              </div>
              : null}
          </div>
        </div>
    );
  }, [addSpecialRequests,
    ref,
    description,
    dropModifier,
    error?.kind,
    error?.message,
    groups,
    image,
    loading,
    name,
    quantity,
    setQuantity,
    specialRequestsContent,
    specialRequestsEnabled,
    specialRequestsPlaceholder,
    isSelected,
    deselect,
    displayedModifier?.groupGuid,
    displayedModifier?.modifier.itemGuid,
    timeBasedRules,
    fulfillmentTimeValid,
    backgroundColor,
    shouldShowHighlights]);

  return (
    <>
      {modifiers.length > 1 && displayedModifier ?
        <div key={displayedModifier.modifier.itemGuid} className="background" aria-hidden>
          {getDisplay(displayedModifier, false, false)}
        </div>
        : null}
      <AnimatePresence exitBeforeEnter>
        <React.Fragment key={`schema-${displayedModifier?.modifier.itemGuid}`}>
          {schema}
        </React.Fragment>
        {previousModifier ?
          <div key={`animate-${previousModifier.modifier.itemGuid}`} className="background" aria-hidden>
            {getDisplay(previousModifier, false, false)}
          </div>
          : null}
        <React.Fragment key={`animate-${displayedModifier?.modifier.itemGuid}`}>
          {getDisplay(displayedModifier, !wasPrev, true)}
        </React.Fragment>
      </AnimatePresence>
    </>
  );
};

const ModalBody = React.forwardRef(WrappedModalBody);

const WrappedItemModal = (props: Props) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const { loadingCart: loadingCartState } = useCart();
  const { fulfillmentData } = useFulfillment();
  const { isOpen, itemGuid, itemGroupGuid, restaurantGuid, shortUrl } = props;

  const { openAlertModal } = useAlertModalContext();

  const shouldSkip = !restaurantGuid || !shortUrl || !itemGuid || !itemGroupGuid || !isOpen;
  const hasCartSelection = props.cartGuid && props.selectionGuid;

  const { data, error, loading: loadingDetails, called: calledDetails } = useMenuItemDetailsQuery({
    variables: {
      input: {
        itemGuid: itemGuid || '',
        itemGroupGuid: itemGroupGuid || '',
        shortUrl: shortUrl || '',
        restaurantGuid: restaurantGuid || '',
        menuApi: MenuApiType.Do,
        dateTime: fulfillmentData?.cartFulfillmentData.fulfillmentDateTime
      },
      nestingLevel: 10
    },
    skip: shouldSkip || !isOpen || !!hasCartSelection
  });

  const [loadCartSelection, {
    data: cartSelection,
    error: errorCart,
    loading: loadingCart,
    called: calledCart
  }] = useMenuItemFromCartLazyQuery({
    variables: {
      input: {
        itemGuid: itemGuid || '',
        itemGroupGuid: itemGroupGuid || '',
        shortUrl: shortUrl || '',
        restaurantGuid: restaurantGuid || '',
        menuApi: MenuApiType.Do,
        selectionGuid: props.selectionGuid || '',
        cartGuid: props.cartGuid || '',
        dateTime: fulfillmentData?.cartFulfillmentData?.fulfillmentDateTime
      },
      nestingLevel: 10
    },
    fetchPolicy: 'no-cache'
  });

  const { data: doMenuItemData, error: errorMenuItem } = useDoMenuItemQuery({
    variables: {
      input: {
        menuGroupGuid: itemGroupGuid || '',
        menuItemGuid: itemGuid || '',
        restaurantGuid: restaurantGuid || '',
        // eslint-disable-next-line camelcase
        visibility: Menus_VisibilityEnum.ToastOnlineOrdering,
        dateTime: fulfillmentData?.cartFulfillmentData.fulfillmentDateTime
      }
    },
    skip: shouldSkip || !isOpen
  });

  useEffect(() => {
    if(error || errorCart || errorMenuItem) {
      openAlertModal(REQUEST_FAILURE_MESSAGE);
      reportError('Error loading menu item.', error || errorCart || errorMenuItem);
    }
  }, [error, errorCart, errorMenuItem, openAlertModal]);

  const modifierMap = doMenuItemData?.doMenus_findMenuItem?.__typename === 'DoMenus_FindMenuItemResponse'
    ? doMenuItemData?.doMenus_findMenuItem?.menuResponse?.modifierGroupReferences?.reduce((map, ref) => ({
      ...map,
      [ref.guid]: ref
    }), {})
    : {};

  const loading = calledDetails && loadingDetails || calledCart && loadingCart;

  useEffect(() => {
    if(!shouldSkip && isOpen && hasCartSelection) {
      loadCartSelection();
    }
  }, [shouldSkip, isOpen, hasCartSelection, loadCartSelection]);

  const modifierGroups = useMemo(() => data?.menuItemDetails.modifierGroups ? collapseModifierGroups(data?.menuItemDetails.modifierGroups) : {}, [data]);

  const rootModifier = useMemo(() => {
    const getModifiersFromCartQuery = (cartSelection: MenuItemFromCartQuery) => {
      return {
        ...collapseModifier({
          ...cartSelection?.selectionItemDetails,
          price: cartSelection?.selectionItemDetails.menuItemPrice
        }),
        specialInstructions: cartSelection?.selectionItemDetails?.specialRequest,
        usesFractionalQuantity: cartSelection?.selectionItemDetails?.usesFractionalQuantity,
        unitOfMeasure: cartSelection?.selectionItemDetails?.fractionalQuantity?.unitOfMeasure,
        name: data?.menuItemDetails.name
      };
    };

    const getModifiersFromMenuQuery = () => {
      return {
        groupGuid: itemGroupGuid || 'root',
        modifier: {
          itemGuid: props.itemGuid!,
          itemGroupGuid,
          modifierGroups: data?.menuItemDetails.modifierGroups || [],
          price: data?.menuItemDetails.prices[0] || 0
        },
        modifierGroups,
        isRoot: true,
        masterId: data?.menuItemDetails?.masterId,
        action: 'replace' as Action,
        price: data?.menuItemDetails.prices[0] || 0,
        usesFractionalQuantity: data?.menuItemDetails?.usesFractionalQuantity,
        unitOfMeasure: data?.menuItemDetails?.unitOfMeasure,
        name: data?.menuItemDetails.name
      };
    };
    return !calledCart || !cartSelection
      ? getModifiersFromMenuQuery()
      : getModifiersFromCartQuery(cartSelection);
  }, [calledCart, cartSelection, data, itemGroupGuid, modifierGroups, props.itemGuid]);

  if(shouldSkip) {
    return null;
  }
  return (
    <ModifierContextProvider modifier={rootModifier} rootRef={rootRef} modifierGroupData={modifierMap}
      quantity={cartSelection?.selectionItemDetails?.fractionalQuantity?.quantity || cartSelection?.selectionItemDetails?.quantity || 1}>
      <div className="itemModalContainer">
        <div className="itemModal">
          <ModalBody
            name={data?.menuItemDetails.name || cartSelection?.selectionItemDetails.name}
            itemGuid={props.itemGuid}
            basePrice={data?.menuItemDetails.prices[0] || 0}
            specialRequestsEnabled={props.specialRequestsEnabled}
            specialRequestsPlaceholder={props.specialRequestsPlaceholder}
            specialRequestsContent={cartSelection?.selectionItemDetails.specialRequest}
            description={data?.menuItemDetails.description || cartSelection?.selectionItemDetails.description}
            groups={!loading ? data?.menuItemDetails.modifierGroups || cartSelection?.selectionItemDetails?.modifierGroups || [] : null}
            image={data?.menuItemDetails?.imageUrls?.medium || cartSelection?.selectionItemDetails?.imageUrls?.medium}
            loading={loading}
            ref={rootRef}
            shouldShowHighlights={props.shouldShowHighlights} />
        </div>
        <ModalActions loading={loading} selectionGuid={props.selectionGuid} />
        {loadingCartState && loadingCart && <LoadingSpinnerOverlay withBorderRadius />}
      </div>
    </ModifierContextProvider>
  );
};

const ItemModal = (props: Props) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay fadeIn={props.withTransitions} fadeOut />
      <ModalContent wrapperClassName="itemModalWrapper" contentClassName="itemModalContent" ariaLabelledBy={headerId}
        slideIn={props.withTransitions} slideOut>
        <WrappedItemModal key="modal" {...props} />
      </ModalContent>
    </Modal>
  );
};

export default ItemModal;
