import React, { useMemo, useState } from 'react';

import { MenuFormatConfig } from 'src/apollo/sites';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import LegacyMenuNav from 'public/components/default_template/menu_nav/LegacyMenuNav';
import OoBasicMenuNav from 'public/components/default_template/menu_nav/OoBasicMenuNav';
import TopMenuNav from 'public/components/default_template/menu_nav/TopMenuNav';
import { MenuItem } from 'public/components/default_template/menu_section/MenuSection';
import { useMenuSearchContext } from 'public/components/default_template/search';


export type RefMenuGroup = {
  name: string;
  description?: string | null;
  ref: React.RefObject<HTMLDivElement>;
  id: string;
  guid: string;
  items: MenuItem[]
}

export type RefMenu = {
  name: string;
  guid: string;
  ref: React.RefObject<HTMLDivElement>;
  groups: RefMenuGroup[];
}

type MenuGroup = {
  name?: string | null;
  guid?: string | null;
  items?: (MenuItem | Partial<MenuItem> | null)[] | null;
}

type Menu = {
  name?: string | null;
  id?: string;
  groups?: (MenuGroup | null)[] | null;
  guid?: string | null;
}

type HookProps = {
  menus?: Menu[] | null;
}

export const useMenuNav = (props: HookProps) => {
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [scrolledMenuIndex, setScrolledMenuIndex] = useState(0);
  const [scrolledGuid, setScrolledGuid] = useState<string>();
  const [selectedGuid, setSelectedGuid] = useState<string>();

  const refMenuPages: RefMenu[] = useMemo(() => props.menus ?
    props.menus.map(menu => ({
      name: menu.name as string,
      guid: menu.guid || menu.id || menu.name as string,
      ref: React.createRef<HTMLDivElement>(),
      groups: menu.groups?.filter(group => group?.items?.length)
        .map((group: any) => ({
          ...group,
          ref: React.createRef<HTMLDivElement>(),
          guid: group.guid || group.name,
          id: group.guid || group.name
        })) || []
    }))
    : [], [props.menus]);

  const pillOptions = useMemo(() =>
    refMenuPages[selectedMenuIndex]?.groups.filter(group => group.items.length)
      .map(group => ({
        guid: group.id,
        anchorRef: group.ref,
        value: group.name
      })) || []
  , [refMenuPages, selectedMenuIndex]);

  return {
    selectedMenuIndex,
    setSelectedMenuIndex,
    scrolledMenuIndex,
    setScrolledMenuIndex,
    scrolledGuid,
    setScrolledGuid,
    selectedGuid,
    setSelectedGuid,
    refMenuPages,
    pillOptions
  };
};

const MenuNav = (
  {
    menus,
    menuGroups,
    selectedMenuIndex,
    setSelectedMenuIndex,
    selectedGroupGuid,
    setSelectedGroupGuid,
    menuConfig,
    navRef
  } : {
    menus: RefMenu[];
    menuGroups: {
      guid: string;
      anchorRef: React.RefObject<HTMLDivElement>;
      value: string;
    }[];
    selectedMenuIndex: number;
    setSelectedMenuIndex: (index: number) => void;
    selectedGroupGuid?: string;
    setSelectedGroupGuid: (guid: string) => void;
    menuConfig?: MenuFormatConfig | null;
    navRef?: React.RefObject<HTMLDivElement>;
  }
) => {
  const { restaurant: { config } } = useRestaurant();
  const { canUseSearch } = useMenuSearchContext();

  const ooBasic = config.isOnlineOrderingOnly && config.hasFullCustomization === false;
  if(ooBasic) {
    if(canUseSearch) {
      return <OoBasicMenuNav
        menus={menus}
        selectedMenuIndex={selectedMenuIndex}
        setSelectedMenuIndex={setSelectedMenuIndex}
        selectedGroupGuid={selectedGroupGuid}
        setSelectedGroupGuid={setSelectedGroupGuid}
        menuConfig={menuConfig}
        navRef={navRef} />;
    } else {
      return <LegacyMenuNav
        menus={menus}
        menuGroups={menuGroups}
        selectedMenuIndex={selectedMenuIndex}
        setSelectedMenuIndex={setSelectedMenuIndex}
        selectedGroupGuid={selectedGroupGuid}
        setSelectedGroupGuid={setSelectedGroupGuid}
        menuConfig={menuConfig}
        navRef={navRef} />;
    }
  } else {
    return <TopMenuNav
      menus={menus}
      selectedMenuIndex={selectedMenuIndex}
      setSelectedMenuIndex={setSelectedMenuIndex}
      selectedGroupGuid={selectedGroupGuid}
      setSelectedGroupGuid={setSelectedGroupGuid}
      menuConfig={menuConfig}
      navRef={navRef} />;
  }
};

export default MenuNav;
