import React, { useMemo } from 'react';

import { ContainsEditableProps, EditableComponentName, FieldType, ModuleField, useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';
import reduce from 'lodash/reduce';
import urljoin from 'url-join';
import isURL from 'validator/es/lib/isURL'; // Tree shakeable import

import { CardImage, Image as ImageObject, ImageFit, Maybe, Scalars } from 'src/apollo/sites';

import { getImageModules } from 'shared/components/common/editor_context/editableUtils';
import Link from 'shared/components/common/link/index';
import { getLinkEnums } from 'shared/components/common/link/util';
import { RestaurantContextType, useOptionalRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { resources } from 'config';


export type SrcSet = string | string[] | { [key: string]: string };

export type ImageProps = {
  eagerLoad?: boolean;
  wrapperClassName?: string;
  className?: string;
  cdnHost?: string | null;
  // If this object is passed, src, alt, and fit will not be read.
  imageObject?: ImageObject | CardImage | null;
  src?: string | null;
  srcSet?: SrcSet
  onError?: () => void;
  alt?: string;
  fit?: ImageFit | null;
  link?: string | null;
  onClick?: React.MouseEventHandler<HTMLImageElement>;
  height?: number;
  onLoad?: (ev: React.SyntheticEvent) => void;
  'data-testid' ?: string;
  decorated?: boolean;
} & EditableProps

type EditableProps = {
  editableComponentName?: EditableComponentName;
  // If this object is passed, altEditPath, fitEditPath, and linkEditPath will not be read.
  imageObjectPath?: string | null;
  altEditPath?: string;
  fitEditPath?: string;
  linkEditPath?: string;
} & Partial<ContainsEditableProps>

const defaultEditableName = 'image';
const maxAltLen = 400;

const isDataUrl = (src: string) => {
  return src.startsWith('data:image');
};

export const getImageUrl = (src?: string | null, cdnHost: string | null = resources.publicAssetURL): string | undefined => {
  if(!src) {
    return undefined;
  }

  if(isDataUrl(src)) {
    return src;
  }

  return isURL(src, { require_protocol: true }) ? src : urljoin(cdnHost || resources.imagePrefix, src);
};

const Image = (
  {
    eagerLoad, cdnHost = resources.publicAssetURL, imageObject, imageObjectPath, src, srcSet, onClick, editPath,
    editableComponentName = defaultEditableName, alt, altEditPath, fit, fitEditPath, link, linkEditPath, className, decorated = false, ...rest
  }: ImageProps
) => {
  const restaurantData = useOptionalRestaurant();

  const imageSrc = useMemo(() =>
    getImageUrl(imageObject?.displaySrc || imageObject?.src || src, imageObject?.displaySrc ? resources.imagePrefix : cdnHost), [src, imageObject, cdnHost]);
  const imageFit = useMemo(() => imageObject?.fit ? imageObject.fit : fit, [imageObject?.fit, fit]);
  const imageLink = useMemo(() => imageObject?.link ? imageObject.link : link, [imageObject?.link, link]);
  const { useEditableRef } = useEditor();

  const editableFields = useMemo(() =>
    editableImageFields(imageObject, imageObjectPath, editPath, imageSrc, altEditPath, alt, fitEditPath, fit, linkEditPath, link, restaurantData),
  [imageObject, imageObjectPath, editPath, imageSrc, altEditPath, alt, fitEditPath, fit, linkEditPath, link, restaurantData]);

  const { editableRef } = useEditableRef<HTMLImageElement>({
    name: editableComponentName,
    path: imageObjectPath || editPath,
    displayName: 'Image',
    schema: { fields: editableFields },
    actions: []
  });

  const imgSrcSet = useMemo(() => {
    if(Array.isArray(srcSet)) {
      return srcSet.reduce((accumulator, imagePath) => `${accumulator ? `${accumulator}, ` : ''}${getImageUrl(imagePath, cdnHost)}`, '');
    } else if(typeof srcSet === 'object') {
      return reduce(srcSet, (accumulator, curVal, curKey) => `${getImageUrl(curVal || '', cdnHost)} ${curKey}${accumulator && `, ${accumulator}`}`, '');
    }

    return srcSet;
  }, [cdnHost, srcSet]);

  const overlayStyles = useMemo(() => {
    return imageObject?.overlay && imageObject?.overlay?.show
      ? {
        background: imageObject.overlay.color || '#000000',
        opacity: `${(imageObject.overlay.opacity || 50) / 100}`
      }
      : {};
  }, [imageObject?.overlay]);

  const shouldMakeEditable = editPath || imageObjectPath || editableComponentName !== defaultEditableName;
  const showModifications = imageSrc && !isDataUrl(imageSrc);

  if(!src && !imageObject?.src) return null;

  const image = (_ref: any = undefined, _onClick: any = undefined) => <img src={imageSrc} srcSet={imgSrcSet}
    ref={shouldMakeEditable ? _ref : undefined} onClick={_onClick}
    loading={eagerLoad ? 'eager' : 'lazy'}
    alt={imageObject?.altText ? imageObject.altText : alt}
    className={classnames(className, getImageShapeClass(imageObject?.shape, className), {
      imageContain: imageFit === ImageFit.Contain,
      imageCover: imageFit !== ImageFit.Contain,
      flippedHorizontally: showModifications && imageObject?.modifications?.flippedHorizontally,
      flippedVertically: showModifications && imageObject?.modifications?.flippedVertically
    })}
    {...rest} />;
  const decoratedImage = decorated
    ?
    <div className={`styledImageWrapper ${getImageShapeClass(imageObject?.shape, className)}`} ref={shouldMakeEditable ? editableRef : undefined} onClick={onClick}>
      {image()}
      <div className={'styledImageOverlay'} style={{ ...overlayStyles }}>
      </div>
    </div>
    : image(shouldMakeEditable ? editableRef : undefined, onClick);
  return imageLink ? <Link href={imageLink}>{decoratedImage}</Link> : decoratedImage;
};

// Exported for testing
export const editableImageFields = (
  imageObject: ImageObject | CardImage | null | undefined,
  imageObjectPath: string | null | undefined,
  editPath: string | undefined,
  imageSrc: string | undefined,
  altEditPath: string | undefined,
  alt: string | undefined,
  fitEditPath: string | undefined,
  fit: ImageFit | null | undefined,
  linkEditPath: string | undefined,
  link: string | null | undefined,
  restaurantData: RestaurantContextType | undefined
): ModuleField[] => {
  const config = restaurantData?.restaurant.config;
  const sitePages = restaurantData?.sitePages;
  const linkEnums = getLinkEnums( { config, sitePages } );

  const fitEnums = [
    { displayName: 'Fill', value: ImageFit.Cover },
    { displayName: 'Fit', value: ImageFit.Contain }
  ];

  const fields: ModuleField[] = [];
  if(imageObject && imageObjectPath) {
    fields.push(...getImageModules(imageObjectPath, imageObject));
  } else {
    fields.push({ displayName: 'Image', path: editPath || '', type: FieldType.Image, value: imageSrc });
    if(altEditPath) {
      fields.push({ displayName: 'Alt Text', path: altEditPath, type: FieldType.Text, value: alt || '', validation: { maxLen: maxAltLen } });
    }
    if(fitEditPath) {
      fields.push({ displayName: 'Image Fit', path: `${fitEditPath}` || '', type: FieldType.Enum, value: fit, enums: fitEnums });
    }
    if(linkEditPath) {
      fields.push({ displayName: 'Links to', path: `${linkEditPath}` || '', type: FieldType.EnumOrOther, value: link, enums: linkEnums });
    }
  }

  return fields;
};

const getImageShapeClass = (shape: Maybe<Scalars['String']> = 'none', className: string = '') => {
  const inheritsRoundedStyle = className.includes('rounded');
  const inheritsLeafStyle = className.includes('halfLeaf');
  let theme = shape;
  if(shape === 'none' && (inheritsLeafStyle || inheritsRoundedStyle)) {
    theme = inheritsRoundedStyle ? 'rounded' : 'leaf';
  }
  switch(theme) {
    case 'square': return 'square_shape';
    case 'outline': return 'outline_shape';
    case 'leaf': return 'leaf_shape';
    case 'rounded': return 'rounded_shape';
    case 'none':
    default: return '';
  }
};

export default Image;
