import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useFormContext, useForm, useWatch } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router';

import { isInternationalCountryIsoCode } from '@toasttab/buffet-pui-country-utilities';
import { AdyenPayment } from '@toasttab/do-secundo-adyen-payment';
import classnames from 'classnames';

import { useIsIntlRestaurant } from 'src/lib/js/hooks/useIsIntlRestaurant';
import { ORDER_KEY } from 'src/public/components/online_ordering/OrderContext';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';
import { useRestaurantRoutes } from 'src/shared/components/common/restaurant_routes/RestaurantRoutesContext';
import { ShowForUS } from 'src/shared/components/common/show_for_us/ShowForUS';
import Warning from 'src/shared/components/common/warning/Warning';

import Image from 'shared/components/common/Image';
import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import UhOh, { getUhOhPropsForError } from 'shared/components/uh_oh/UhOh';

import EmptyCart from 'public/components/default_template/online_ordering/cart/EmptyCart';
import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import CheckoutErrorModal from 'public/components/default_template/online_ordering/checkout/CheckoutErrorModal';
import { GuestCheckoutButton, ToastCheckoutButton } from 'public/components/default_template/online_ordering/checkout/CheckoutModeButtons';
import CheckoutSection from 'public/components/default_template/online_ordering/checkout/CheckoutSection';
import FulfillmentSection from 'public/components/default_template/online_ordering/checkout/FulfillmentSection';
import OrderSection from 'public/components/default_template/online_ordering/checkout/OrderSection';
import OrderPrices from 'public/components/default_template/online_ordering/checkout/payment/OrderPrices';
import PaymentSection from 'public/components/default_template/online_ordering/checkout/payment/PaymentSection';
import PromoCodeInput from 'public/components/default_template/online_ordering/checkout/payment/PromoCodeInput';
import { AcceptedPaymentMethods } from 'public/components/default_template/online_ordering/checkout/payment/SavedCreditCards';
import CheckoutWithVenmoOrPayPal from 'public/components/default_template/paypal/CheckoutWithPayPalOrVenmoButton';
import { useCart } from 'public/components/online_ordering/CartContext';
import { CheckoutFormData, useCheckout } from 'public/components/online_ordering/CheckoutContext';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';
import { PaymentOption, usePayment } from 'public/components/online_ordering/PaymentContext';
import { CompletedOrder } from 'public/components/online_ordering/types';

import { getCustomerInfo, useHandleCompletedOrderCallback } from './checkoutUtils';

const CheckoutForm = () => {
  const { customer, refetchCustomer } = useCustomer();
  const { placeOrder, setOrderError, canCheckout } = useCheckout();
  const { loadingCart, cartGuid, cart, error: cartError } = useCart();
  const isCartLoading = !cart && loadingCart;

  const formMethods = useForm({
    mode: 'onTouched',
    defaultValues: getCustomerInfo(customer)
  });

  const { reset } = formMethods;
  useEffect(() => {
    if(customer) {
      reset(getCustomerInfo(customer));
    }
  }, [reset, customer]);

  const disableSubmit = useMemo(() => !canCheckout(formMethods.formState), [canCheckout, formMethods.formState]);

  const handleCompletedOrder = useHandleCompletedOrderCallback();

  const onSubmit = useCallback(async (checkoutFormData: CheckoutFormData) => {
    try {
      await placeOrder(cartGuid!, checkoutFormData, handleCompletedOrder);
      refetchCustomer();
    } catch(error) {
      setOrderError(error);
    }
  }, [placeOrder, cartGuid, handleCompletedOrder, setOrderError, refetchCustomer]);

  if(cartError) {
    return <UhOh {...getUhOhPropsForError(cartError)} />;
  }

  if(isCartLoading) {
    return (
      <div className="checkoutForm emptyCart">
        <section className="checkoutSection currentSection">
          <Skeleton width="100%" height="500px" />
        </section>
      </div>
    );
  }

  if((cart?.order?.selections?.length || 0) === 0) {
    return <EmptyCart />;
  }

  return (
    <div className="checkoutForm">
      <FormProvider {...formMethods}>
        <form className="checkoutFormContents" onSubmit={formMethods.handleSubmit(onSubmit)}>
          {formMethods.formState.isSubmitting && <LoadingSpinnerOverlay withBorderRadius={false} fullScreen={true} />}
          <CheckoutSections disableSubmit={disableSubmit} formMethods={formMethods} />
        </form>
      </FormProvider>
      <CheckoutErrorModal />
    </div>
  );
};

export const CheckoutSections = ({ disableSubmit, formMethods }: { disableSubmit: boolean, formMethods: any }) => {
  const { customer } = useCustomer();
  const { cart } = useCart();
  const { orderTotal } = useCheckout();
  const { paymentOption } = usePayment();
  const isIntlRestaurant = useIsIntlRestaurant();
  const [selectedCheckoutMode, setSelectedCheckoutMode] = useState(false);
  const [showIntlPayment, setShowIntlPayment] = useState<boolean>(false);
  const canPayAtCheckout = !(cart?.paymentOptions.atCheckout.length === 0 && cart?.paymentOptions.uponReceipt.length > 0);
  const showUSPayments = useMemo(() => (selectedCheckoutMode || Boolean(customer)) && !showIntlPayment, [customer, selectedCheckoutMode, showIntlPayment]);

  return (
    <div className="checkoutFormContents">
      <FulfillmentSection complete={selectedCheckoutMode} />
      <OrderSection />
      {!selectedCheckoutMode && !customer &&
          <>
            <PromoCodeInput />
            <OrderPrices />
            <div className="checkoutModeButtonGroup">
              <ShowForUS>
                <ToastCheckoutButton onClickCheckoutAsGuest={() => setSelectedCheckoutMode(true)} />
              </ShowForUS>
              <GuestCheckoutButton onClick={() => setSelectedCheckoutMode(true)} />
            </div>
            {canPayAtCheckout &&
              <CheckoutSection>
                <span>We accept</span>
                <AcceptedPaymentMethods />
              </CheckoutSection>}
          </>}
      <AnimatedSection expanded={showUSPayments} slowTransition testid="animated-payment-section" >
        <div className="checkoutFormContents" data-testid="checkoutFormInfo">
          <PaymentSection isExpanded={showUSPayments} />
          <CheckoutSection>
            <div className="sectionButton">
              {isIntlRestaurant && orderTotal > 0 && canPayAtCheckout && paymentOption === PaymentOption.CreditCard ?
                <ContinueIntlPayment disableSubmit={disableSubmit} formMethods={formMethods} enableIntlPayment={() => setShowIntlPayment(true)} /> :
                <SubmitOrderButton disableSubmit={disableSubmit} formMethods={formMethods} />}
              <CheckoutNote />
            </div>
          </CheckoutSection>
        </div>
      </AnimatedSection>
      <InternationalPaymentContainer expanded={showIntlPayment} hideIntlPayment={() => setShowIntlPayment(false)} />
    </div>
  );
};

const PlaceOrderTipWarning = () => {
  return (
    <Warning dataTestId="place-order-tip-warning" message="Please add another payment method for the tip" />
  );
};

/**
 * if a GC is applied and a non-zero tip is selected, present a warning to the guest stating they need to provide another payment method.
 * OO does not support allowing GC's to cover tip amounts
 * @param disableSubmit
 *  Used to check if submission is disabled due to lacking form information.
 *  For international rxs we can show the warning regardless, since payment is taken after this form is submitted.
 * @param formMethods used to ensure the place order form is not currently being submit
 * @param giftCardAppliedAmount
 * @param tipAmount
 * @returns true, if warning should show to guest
 */
function shouldDisplayTipWarning(disableSubmit: boolean, formMethods: any, giftCardAppliedAmount: number, tipAmount: number, isIntlRestaurant: boolean) {
  return !formMethods?.formState?.isSubmitting
          && (disableSubmit || isIntlRestaurant) && giftCardAppliedAmount > 0 && tipAmount > 0;
}

export const SubmitOrderButton = ({ disableSubmit, formMethods }: {disableSubmit: boolean, formMethods: any}) => {
  const { paymentOption, tipAmount } = usePayment();
  const { giftCardAppliedAmount } = useCheckout();
  const isIntlRestaurant = useIsIntlRestaurant();

  if(paymentOption === PaymentOption.ApplePay) {
    return <button data-testid="applePaySubmitButton" type="submit" className="submitButton applePayBtn" disabled={disableSubmit} />;
  }
  if(paymentOption === PaymentOption.Paypal || paymentOption === PaymentOption.Venmo) {
    return <CheckoutWithVenmoOrPayPal
      disableSubmit={disableSubmit}
      paymentOption={paymentOption} />;
  }

  const displayTipPaymentMethodWarning = shouldDisplayTipWarning(disableSubmit, formMethods, giftCardAppliedAmount, tipAmount, isIntlRestaurant);
  return (
    <>
      <button
        data-testid="basicSubmitButton"
        type="submit"
        className={classnames('submitButton primaryCta', !disableSubmit && 'primaryColorBackground primaryColorHover')}
        disabled={disableSubmit}>
        Place Order
      </button>
      {displayTipPaymentMethodWarning &&
      <PlaceOrderTipWarning />}
    </>
  );
};

const ContinueIntlPayment = ({ disableSubmit, formMethods, enableIntlPayment }: {disableSubmit: boolean, formMethods: any, enableIntlPayment: () => void}) => {
  const { giftCardAppliedAmount } = useCheckout();
  const { tipAmount } = usePayment();
  const isIntlRestaurant = useIsIntlRestaurant(); // should always be true in this component, but use for consistency

  const displayTipPaymentMethodWarning = shouldDisplayTipWarning(disableSubmit, formMethods, giftCardAppliedAmount, tipAmount, isIntlRestaurant);

  return (
    <>
      <button
        data-testid="continueButtonPayment"
        type="submit"
        onClick={e => {
          e.preventDefault();
          enableIntlPayment();
        }}
        disabled={disableSubmit}
        className={classnames('submitButton primaryCta', !disableSubmit && 'primaryColorBackground primaryColorHover')}>
          Continue to payment
      </button>
      {displayTipPaymentMethodWarning &&
        <PlaceOrderTipWarning />}
    </>
  );
};

// eslint-disable-next-line react/display-name
export const InternationalPaymentContainer = memo(({ expanded, hideIntlPayment }: { expanded: boolean, hideIntlPayment: () => void }) => {
  const { ooRestaurant } = useRestaurant();
  const { orderTotal, placeOrder, setOrderError } = useCheckout();
  const { tipAmount } = usePayment();

  const { cartGuid, clearCart } = useCart();
  const { getValues: getCheckoutValues } = useFormContext<CheckoutFormData>();
  const { confirmationPath } = useRestaurantRoutes();
  const { refetchCustomer } = useCustomer();

  const i18n = ooRestaurant?.i18n;
  const guid = ooRestaurant?.guid;
  const onlineOrderingEnabled = ooRestaurant?.onlineOrderingEnabled;
  const history = useHistory();

  const [showPlaceOrderOverlay, setShowPlacOrderOverlay] = useState<boolean>(false);

  const handleCompletedOrder = useCallback((completedOrder: CompletedOrder) => {
    if(completedOrder?.guid) {
      clearCart();
      setShowPlacOrderOverlay(false);
      localStorage.setItem(ORDER_KEY, completedOrder.guid);
      history.push(confirmationPath, { completedOrder });
    }
  }, [clearCart, history, confirmationPath]);

  const placeCCOrder = useCallback(async (paymentIntentID: string | undefined, cartGuid: string | null | undefined, checkoutFormData: CheckoutFormData) => {
    const formData = {
      ...checkoutFormData,
      semiPaymentIntentId: paymentIntentID
    };

    try {
      setShowPlacOrderOverlay(true);
      await placeOrder(cartGuid!, formData, handleCompletedOrder);
      refetchCustomer();
    } catch(error) {
      setOrderError(error);
    }
  }, [placeOrder, handleCompletedOrder, setOrderError, refetchCustomer]);

  const guestInfo = {
    yourInfoFirstName: useWatch({ name: 'yourInfoFirstName' }),
    yourInfoLastName: useWatch({ name: 'yourInfoLastName' }),
    yourInfoEmail: useWatch({ name: 'yourInfoEmail' }),
    yourInfoPhone: useWatch({ name: 'yourInfoPhone' })
  };

  if(!onlineOrderingEnabled) {
    return <div data-testid="paymentUnavailable">Online ordering payments unavailable</div>;
  }

  return (
    <>
      {showPlaceOrderOverlay && <LoadingSpinnerOverlay withBorderRadius={false} fullScreen={true} />}
      <AnimatedSection expanded={expanded}>
        <div className="internationalPaymentContainer">
          <div className={classnames('internationalPaymentHeader collapsable')} onClick={() => hideIntlPayment()}>
            Payment details
            <Image alt={`${expanded ? 'Collapse' : 'Expand'} section`} className={classnames('controlIcon', expanded ? 'rotatedUp' : 'rotatedDown')} src="icons/chevron-right-gray.svg" />
          </div>

          <div data-testid={'internationalPaymentContainer'}>
            {expanded && i18n &&
              <AdyenPayment
                payment={{ checkAmount: orderTotal - tipAmount, tipAmount, emailAddress: guestInfo.yourInfoEmail }}
                config={{ currency: i18n.currency, locale: i18n.locale, country: i18n.country, guid, sourceApp: 'ONLINE_ORDERING' }}
                onPaymentCompleted={paymentIntentID => {
                  placeCCOrder(paymentIntentID, cartGuid, getCheckoutValues());
                }} />}
            <p data-testid="intlPaymentLegalStatement" className="note checkoutNote" role="contentinfo">
              I understand my information will be used to process this payment and for other purposes outlined in Toast&apos;s{' '}
              <a href="https://pos.toasttab.com/privacy" rel="noopener noreferrer" target="_blank">Privacy Statement</a>, and will also be disclosed to the merchant.
            </p>
          </div>
        </div>
        <CheckoutNote />
      </AnimatedSection>
    </>
  );
});

const CheckoutNote = () => {
  const { ooRestaurant } = useRestaurant();

  const i18n = ooRestaurant?.i18n;
  const international = isInternationalCountryIsoCode(i18n?.country || 'US');

  return (
    international ?
      <p data-testid="intlCheckoutLegalStatement" className="note checkoutNote" role="contentinfo">
        By placing your order, you authorise Toast and this restaurant or restaurant group
        to email you digital receipts and order updates, and to use your phone number to communicate order updates,
        subject to Toast&apos;s <a href="https://pos.toasttab.com/terms-of-service/#diner-tos" rel="noopener noreferrer" target="_blank">Guest Terms of Service</a>{' '}
        and <a href="https://pos.toasttab.com/privacy" rel="noopener noreferrer" target="_blank">Privacy Statement</a>.
      </p>
      :
      <p data-testid="checkoutLegalStatement" className="note checkoutNote" role="contentinfo">
          By completing your order, you give Toast and this restaurant or restaurant group permission to send you digital receipts,
          order updates powered by Toast, and marketing materials. Info for CA residents available{' '}
        <a href="https://pos.toasttab.com/privacy#addendum-a" rel="noopener noreferrer" target="_blank">here</a>.
          Message frequency varies and message and data rates may apply. Reply STOP to opt out. Program is subject to the Toast{' '}
        <a href="https://pos.toasttab.com/terms-of-service/#diner-tos" rel="noopener noreferrer" target="_blank">Guest Terms of Service</a>
        {' '}and Toast&apos;s{' '}<a href="https://pos.toasttab.com/privacy" rel="noopener noreferrer" target="_blank">Privacy Statement</a>.
      </p>
  );
};

export default CheckoutForm;
