import React from 'react';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';

import Image from 'shared/components/common/Image';
import { ToggleInput } from 'shared/components/common/forms';
import { ShowForUS } from 'shared/components/common/show_for_us/ShowForUS';

import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import PayPalCheckoutMethod from 'public/components/default_template/paypal/PayPalCheckoutMethod';
import { useCheckout } from 'public/components/online_ordering/CheckoutContext';
import { PaymentOption } from 'public/components/online_ordering/PaymentContext';
import { usePaypal } from 'public/components/online_ordering/paypalUtils';
import { Cart } from 'public/components/online_ordering/types';

/** Set of radio buttons for selecting payment options (e.g. Pay now/later, credit/debit/ApplePay/PayPal/Venmo) */
const PaymentOptionsSection = ({
  paymentOption,
  setPaymentOption,
  cart
} : {
  paymentOption: PaymentOption | null,
  setPaymentOption: (option: PaymentOption) => void,
  cart: Cart
}) => {
  const { enabledPaymentOptions } = useCheckout();
  const { isPaypalScriptLoading } = usePaypal();

  const canPayLater = enabledPaymentOptions.has(PaymentOption.UponReceipt);
  const canPayNow = enabledPaymentOptions.has(PaymentOption.CreditCard);

  if(!canPayLater && !canPayNow) {
    return null;
  }

  if(!canPayNow) {
    const message =
      cart.diningOptionBehavior === DiningOptionBehavior.Delivery ?
        'Please pay delivery driver upon receiving your order.' :
        'Please pay at the restaurant when you pick up your order.';
    return <div className="payAtRestaurantNote">{message}</div>;
  }

  const showPaymentTypeButtons = enabledPaymentOptions.size > 1 && paymentOption !== PaymentOption.UponReceipt;

  return (
    <div data-testid="payment-option-radio-buttons">
      {canPayLater &&
        <div className="paymentButtons">
          <ToggleInput
            checked={paymentOption !== PaymentOption.UponReceipt}
            onChange={() => setPaymentOption(PaymentOption.CreditCard)}
            name="payNow"
            id="payNow"
            type="radio"
            dataTestId="input-payNow">
            Pay now
          </ToggleInput>
          <ToggleInput
            checked={paymentOption === PaymentOption.UponReceipt}
            onChange={() => setPaymentOption(PaymentOption.UponReceipt)}
            name="payLater"
            id="payLater"
            type="radio"
            dataTestId="input-payLater">
            {cart.diningOptionBehavior === DiningOptionBehavior.Delivery ? 'Pay upon delivery' : 'Pay at restaurant'}
          </ToggleInput>
        </div>}
      <ShowForUS>
        <AnimatedSection expanded={showPaymentTypeButtons} testid="pay-now-options-animated-section">
          <hr />
          <div className="paymentButtons">
            {enabledPaymentOptions.has(PaymentOption.ApplePay) &&
            <ToggleInput
              checked={paymentOption === PaymentOption.ApplePay}
              onChange={() => setPaymentOption(PaymentOption.ApplePay)}
              name="applepay-checkoutMethod"
              id="applyPay"
              type="radio"
              dataTestId="input-applePay">
              <Image className="appleLogo" alt="ApplePay" src="/icons/apple-pay.svg" />
            </ToggleInput>}
            {enabledPaymentOptions.has(PaymentOption.Paypal) &&
            <ToggleInput
              disabled={isPaypalScriptLoading}
              checked={paymentOption === PaymentOption.Paypal}
              onChange={() => setPaymentOption(PaymentOption.Paypal)}
              name={`${PaymentOption.Paypal}-checkoutMethod`}
              id={`${PaymentOption.Paypal}-payment-method`}
              type="radio"
              dataTestId={`input-${PaymentOption.Paypal}`}>
              <PayPalCheckoutMethod paymentOption={PaymentOption.Paypal} />
            </ToggleInput>}
            {enabledPaymentOptions.has(PaymentOption.Venmo) &&
            <ToggleInput
              disabled={isPaypalScriptLoading}
              checked={paymentOption === PaymentOption.Venmo}
              onChange={() => setPaymentOption(PaymentOption.Venmo)}
              name={`${PaymentOption.Venmo}-checkoutMethod`}
              id={`${PaymentOption.Venmo}-payment-method`}
              type="radio"
              dataTestId={`input-${PaymentOption.Venmo}`}>
              <PayPalCheckoutMethod paymentOption={PaymentOption.Venmo} />
            </ToggleInput>}
            <ToggleInput
              checked={paymentOption === PaymentOption.CreditCard}
              onChange={() => setPaymentOption(PaymentOption.CreditCard)}
              name="card-checkoutMethod"
              id="payWithCard"
              type="radio"
              dataTestId="input-cc">
                Debit or credit card
            </ToggleInput>
          </div>
        </AnimatedSection>
      </ShowForUS>
    </div>
  );
};

export default PaymentOptionsSection;
