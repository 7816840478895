import React from 'react';

export const PickupIcon = () => {
  return (
    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* eslint-disable-next-line max-len */}
      <path d="M6.24524 19H15.264V5.47179H1.73584V19L3.99054 16.7453L6.24524 19ZM6.24524 19V5.47179M3.99053 5.47182V3.51775C3.99053 3.51775 3.95296 1 6.24523 1C8.46236 1 8.49994 3.51775 8.49994 3.51775M8.49994 3.51775V5.47182M8.49994 3.51775C8.49994 3.51775 8.46236 1 10.7546 1C12.9718 1 13.0093 3.51775 13.0093 3.51775V5.47182"
        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};


export const DeliveryIcon = () => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* eslint-disable-next-line max-len */}
      <path d="M5.91663 13.2084C5.91663 14.2669 5.05851 15.125 3.99996 15.125C2.94141 15.125 2.08329 14.2669 2.08329 13.2084C2.08329 12.1498 2.94141 11.2917 3.99996 11.2917C5.05851 11.2917 5.91663 12.1498 5.91663 13.2084ZM5.91663 13.2084H11.1666M17.3333 9.70837C17.1666 6.87504 14.5833 4.70837 14.5 4.45837L14.0833 1.45837C13.9166 1.20837 13.6666 0.958374 13.3333 0.958374H10.8333H11.6666V8.62504C11.6666 9.37504 11.0833 9.95837 10.3333 9.95837H9.41663C9.24996 9.95837 9.08329 9.95837 8.91663 9.87504C8.16663 9.62504 7.74996 8.87504 7.74996 8.12504V6.70837H0.666626M1.91663 13.125C1.08329 12.875 0.583293 12.0417 0.749959 11.125L1.66663 6.79171M17.1666 13.2084C17.1666 14.2669 16.3085 15.125 15.25 15.125C14.1914 15.125 13.3333 14.2669 13.3333 13.2084C13.3333 12.1498 14.1914 11.2917 15.25 11.2917C16.3085 11.2917 17.1666 12.1498 17.1666 13.2084ZM4.91663 2.70837H3.16663C2.66663 2.70837 2.33329 3.12504 2.33329 3.54171V6.70837H5.74996V3.54171C5.74996 3.12504 5.33329 2.70837 4.91663 2.70837Z"
        stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};
