import React from 'react';
import { Link as ClientLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { useEditor } from '@toasttab/sites-components';
import classNames from 'classnames';
import urljoin from 'url-join';
import isURL from 'validator/es/lib/isURL';

import { AttributionContextType, useAttribution } from 'shared/components/common/attribution_context/AttributionContext';

import { usePageDomains } from 'public/js/PageDomainContext';

import { server } from 'config';

const NEW_TAB_PROPS = {
  target: '_blank',
  rel: 'noopener noreferrer'
};

type AttributionOverride = {
  attributionOverride?: AttributionContextType // override AttributionContext link attribution fields.
  blockAttribution?: boolean // if true, removes any and all attribution on a link.
  sameTab?: boolean
}

export type LinkProps = {
  isBold?: boolean;
  icon?: JSX.Element;
} & React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & AttributionOverride;

// Returns True if urlStr links to toasttab.com. False otherwise.
export const isToastTabLink = (urlStr: string): boolean => {
  try {
    const url = new URL(urlStr);
    const isToastLink = url.hostname.endsWith('toasttab.com');
    return isToastLink;
  } catch(error) {
    // urlStr is not URL
    return false;
  }
};

// to make the links more accessible for keyboard access
export const handleLinkKeyDown = (e: React.KeyboardEvent, href?: string) => {
  if(e.key === ' ' || e.key === 'Spacebar' || e.key === 'Enter') {
    e.preventDefault(); // Prevent the default space key action (page scroll)
    e.stopPropagation();
    if(href?.startsWith('#')) {
      const el = document.getElementById(href.slice(1));
      if(el) {
        el.scrollTo();
        el.focus();
      }
    } else {
      window.location.href = '' + href; // Navigate to the link's href
    }
  }
};

const InactiveLink = (props: LinkProps) => {
  return <a className={classNames(props.className, props.isBold ? 'bold' : '')} title="Links are inactive in editor mode." style={props.style}>{props.children}</a>;
};

const Link = (props: LinkProps) => {
  const { isBold, attributionOverride, blockAttribution, sameTab, ...linkProps } = props;
  const { isEditor } = useEditor();
  const { host, pageDomainOverrides, overriddenDomains, rootDomain } = usePageDomains();

  let linkAttribution = useAttribution();
  if(blockAttribution) {
    linkAttribution = {};
  } else if(attributionOverride) {
    linkAttribution = { ...linkAttribution, ...attributionOverride };
    // Remove keys with empty, undefined, or falsy values.
    linkAttribution = Object.fromEntries(Object.entries(linkAttribution).filter(([_, v]) => !!v));
  }

  if(!props.href) {
    return null;
  }
  if(isEditor) {
    // links inside the Editor should do nothing.
    return <InactiveLink {...props}>{props.children}</InactiveLink>;
  }
  const maybeWithIcon = (_children: any) => {
    if(props.icon) {
      return <div className="linkChildrenWrapper">{props.icon}{_children}</div>;
    } else {
      return _children;
    }
  };

  if(isURL(props.href) || props.href.startsWith('tel:') || props.href.startsWith('mailto:')) {
    if(isToastTabLink(props.href)) {
      const url = new URL(props.href);
      Object.entries(linkAttribution).forEach(([key, value]) => {
        url.searchParams.append(key, value);
      });
      return (
        <a {...linkProps} href={url.toString()} className={classNames(props.className, { bold: isBold })} {...(sameTab ? {} : NEW_TAB_PROPS)}
          onKeyDown={e => handleLinkKeyDown(e, url.toString())} style={props.style}>
          {maybeWithIcon(props.children)}
        </a>
      );
    }
    return <a {...linkProps} {...(sameTab ? {} : NEW_TAB_PROPS)}>{maybeWithIcon(props.children)}</a>;
  } else if(props.href.startsWith('#')) {
    return (
      <HashLink style={props.style} to={props.href} onKeyDown={e => handleLinkKeyDown(e, props?.href)} className={classNames(props.className, isBold ? 'bold' : '')} onClick={props.onClick} >
        {maybeWithIcon(props.children)}
      </HashLink>);
  } else {
    if(isToastTabLink(props.href)) {
      const utmParamString = new URLSearchParams(linkAttribution).toString();
      return (
        <ClientLink to={{ pathname: props.href, search: utmParamString }} className={props.className} style={props.style} onClick={props.onClick}>
          {maybeWithIcon(props.children)}
        </ClientLink>
      );
    }

    // Check if one of the customer's other domains/subdomains is the preferred host for the link's path
    const linkDomain = preferredDomainForPath(props.href, pageDomainOverrides);

    if(linkDomain) {
      if(host !== linkDomain) {
        // This link is mapped to a domain that isn't the current domain, so link to that domain.
        // props.onClick is not passed to the anchor tag here because it can cause changes to the current page before the new page renders,
        // which is jarring
        const url = urljoin(`${server.protocol}://${linkDomain}`, props.href);
        return (
          <a href={url} className={classNames(props.className, { bold: isBold })} onKeyDown={e => handleLinkKeyDown(e, url)} style={props.style}>
            {maybeWithIcon(props.children)}
          </a>
        );
      } else {
        // Already on the mapped domain, so stay on it.
        return (
          <ClientLink to={props.href} className={classNames(props.className, { bold: isBold })} onKeyDown={e => handleLinkKeyDown(e, props?.href)} style={props.style} onClick={props.onClick}>
            {maybeWithIcon(props.children)}
          </ClientLink>
        );
      }
    } else if(overriddenDomains.has(host)) {
      // The current domain is mapped to a page and this link is not mapped to a domain,
      // so go to the root domain.
      const url = urljoin(`${server.protocol}://${rootDomain}`, props.href);
      return (
        <a href={url} className={classNames(props.className, { bold: isBold })} onKeyDown={e => handleLinkKeyDown(e, url)} style={props.style}>
          {maybeWithIcon(props.children)}
        </a>
      );
    }

    // the current domain is not mapped to a page, and the link is not mapped to a domain,
    // so stay on this domain
    return (
      <ClientLink to={props.href} className={classNames(props.className, { bold: isBold })} onKeyDown={e => handleLinkKeyDown(e, props?.href)} style={props.style} onClick={props.onClick}>
        {maybeWithIcon(props.children)}
      </ClientLink>
    );
  }
};

/**
 * Get the overriding/preferred domain for the given path.
 * @param path A path, relative to the domain root.
 * @param pageDomainOverrides A map of path prefixes to their overriding domains.
 * @returns The preferred/overriding domain for the given path. Returns undefined if there are no preferred/overriding domains for the given path.
 */
export const preferredDomainForPath = (path: string, pageDomainOverrides: Map<string, string>): string | undefined => {
  if(pageDomainOverrides.size === 0) {
    return undefined;
  }
  // Check if the first part of the path is overridden by a domain.
  // For example, /order/abc should use the domain that is mapped to /order
  const pathParts = path.split('/');
  return pathParts.length > 1 && pathParts[0] === ''
    ? pageDomainOverrides.get('/' + pathParts[1])
    : pageDomainOverrides.get(path);
};

export default Link;
