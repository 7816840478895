import React, { useEffect } from 'react';

import classnames from 'classnames';

import { useCart } from 'src/public/components/online_ordering/CartContext';
import Popover from 'src/shared/components/common/popover/Popover';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import { usePopoverContext } from 'shared/components/common/popover/PopoverContext';
import { ScreenWidth, useIsMobile } from 'shared/js/utils/WindowContext';

import Cart from './Cart';
import CartIcon from './CartIcon';

export const CART_POPOVER_CONTEXT_KEY = 'cartPopover';

type Props = {
  disabled?: boolean;
}

const CartPopover = (props: Props) => {
  const { cart } = useCart();
  const { ooRestaurant, orderingDisabled } = useRestaurant();
  const cartDisabled = orderingDisabled || !ooRestaurant || props.disabled;
  const numberOfItems = cart?.order?.numberOfSelections || 0;
  const context = usePopoverContext(CART_POPOVER_CONTEXT_KEY);
  const isMobile = useIsMobile(ScreenWidth.SMALL);

  useEffect(() => {
    // Freeze background behind cart when popover is open
    document.body.style.overflowY = context?.isOpen && isMobile ? 'hidden' : 'unset';
    return () => { document.body.style.overflowY = 'unset'; };
  }, [context?.isOpen, isMobile]);

  // NOTE: We currently have the cart take up the entire screen on mobile.
  // A popover is really not the right implementation of such a design, but the cart is currently too
  // closely coupled to the popover context to introduce a second means of governing the cart. We should
  // revisit this popover

  return (
    <Popover
      contextKey={CART_POPOVER_CONTEXT_KEY}
      disabled={cartDisabled}
      firstFocusClassName="checkoutButtonAction"
      contentClassName={isMobile ? 'fullScreenPopover' : undefined}
      customOffset={isMobile ? [0, -56] : undefined} // This enables the popover to start at the top of the page
      target={({ open, isOpenRef }) =>
        <button
          className="targetAction"
          aria-label={`Cart with ${numberOfItems} items`}
          aria-controls="cart-control"
          disabled={cartDisabled}
          aria-expanded={isOpenRef.current}
          onClick={open}>
          <div aria-hidden="true" className={classnames('cartPopoverTarget', { disabled: cartDisabled })}>
            <CartIcon />
            {numberOfItems}
          </div>
        </button>}>
      {(({ close }) =>
        <Cart onClose={close} />
      )}
    </Popover>
  );
};

export default CartPopover;
