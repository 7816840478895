import React from 'react';
import Highlighter from 'react-highlight-words';

import { useMenuSearchContext } from './MenuSearchContext';
import { removeAccents } from './searchFilterUtils';

export const SearchHighlightedText = ({ text }: { text: string}) => {
  const { searchRegExp } = useMenuSearchContext();

  return (
    <Highlighter
      sanitize={removeAccents}
      searchWords={[searchRegExp]}
      textToHighlight={text} />
  );
};
