import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { SearchHighlightedText } from 'public/components/default_template/search';

export const MenuHeader = ({ header }: { header: string | null | undefined; } ) => {
  return (
    <h2 className="header">
      {header
        ? <SearchHighlightedText text={header} />
        : <Skeleton width="100%" height="100%" />}
    </h2>
  );
};
