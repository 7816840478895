import React, { useEffect } from 'react';

import classnames from 'classnames';

import { MenuFormatConfig, MenuTemplate } from 'src/apollo/sites';
import Image from 'src/shared/components/common/Image';

import { RefMenu } from 'public/components/default_template/menu_nav/MenuNav';
import { MenuItem } from 'public/components/default_template/menu_nav/TopMenuNav';
import { SearchInput } from 'public/components/default_template/search';

import { ScrollDirection, useHorizontalScroll } from './menuNavScrollUtils';

type Props = {
  menus: RefMenu[];
  selectedMenuIndex: number;
  setSelectedMenuIndex: (index: number) => void;
  selectedGroupGuid?: string;
  setSelectedGroupGuid: (guid: string) => void;
  menuConfig?: MenuFormatConfig | null;
  navRef?: React.RefObject<HTMLDivElement>;
}

const OoBasicMenuNav = (props: Props) => {
  return (
    <div className="paddedContentWrapper">
      <div className={classnames('paddedContent')}>
        <div className="ooBasicMenuNav" ref={props.navRef} role="navigation" data-testid="oo-basic-menu-nav">

          <div className="ooBasicMenuNavMenuContainer">
            <MenuTabs menus={props.menus} selectedIndex={props.selectedMenuIndex} setSelectedIndex={props.setSelectedMenuIndex} menuConfig={props.menuConfig} />
          </div>

          <div className="ooBasicMenuNavSearchContainer">
            <SearchInput />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OoBasicMenuNav;

const MenuTabs = (props: {
  menus: RefMenu[];
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
  menuConfig?: MenuFormatConfig | null;
}) => {
  const { scrollContainerRef, showLeftArrow, showRightArrow, scroll, scrollEvent } = useHorizontalScroll();

  useEffect(() => {
    scrollEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.menus.length]);

  let menus = props.menus;
  if(props.menus.length === 1) {
    // in the case that there is only one menu, show each of its groups in the navigation instead of one list item with a dropdown
    menus = menus[0]!.groups.map(group => { return { ...group, groups: [] } as RefMenu; });
  }

  if(menus.length === 0) {
    return null;
  }

  return (
    <nav role="tablist" className={classnames('menuTabs', { condensed: props.menuConfig?.template === MenuTemplate.Condensed })} data-testid="menu-tabs">
      <div className="menuTabsWrapper">
        <button type="button" onClick={scroll(ScrollDirection.Backwards)} className={classnames('arrow leftArrow', { arrowHidden: !showLeftArrow })} aria-hidden={true}>
          <Image src="icons/caret-left.svg" eagerLoad alt="Scroll menus left" />
        </button>
        <div className="sections" ref={scrollContainerRef} onScroll={scrollEvent} role="tablist">
          {menus.map((menuItem, index) =>
            <MenuItem
              key={menuItem.name}
              menuItem={menuItem}
              selected={props.selectedIndex === index}
              setSelected={() => props.setSelectedIndex(index) }
              scrollContainerRef={scrollContainerRef}
              hasDropdown={menuItem.groups.length > 1} />)}
        </div>
        <button type="button" onClick={scroll(ScrollDirection.Forwards)} className={classnames('arrow rightArrow', { arrowHidden: !showRightArrow })} aria-hidden={true}>
          <Image src="icons/caret-right.svg" eagerLoad alt="Scroll menus right" />
        </button>
      </div>
    </nav>
  );
};
