import React, { useCallback, useMemo, useReducer } from 'react';
import { generatePath } from 'react-router';

import classnames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import upperFirst from 'lodash/upperFirst';

import { DeliveryInfoInput, DiningOptionBehavior, UpcomingSchedules, useValidateDeliveryDistanceQuery } from 'src/apollo/onlineOrdering';
import { ButtonType } from 'src/apollo/sites';
import { formatTimeString, getDayFromDate, getTakingOrdersTillString, intervalContainsNow, isTodaysSchedule } from 'src/lib/js/schedule';
import Image from 'src/shared/components/common/Image';
import ContextualLoadingSpinner from 'src/shared/components/common/loading_spinner/LoadingSpinner';
import Popover from 'src/shared/components/common/popover/Popover';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';
import { useRestaurantRoutes } from 'src/shared/components/common/restaurant_routes/RestaurantRoutesContext';

import { ButtonLink } from 'shared/components/common/button';

import ToastProduct from 'public/components/online_ordering/ToastProduct';
import { ScheduleType } from 'public/components/online_ordering/types';
import { DeliveryIcon, PickupIcon } from 'public/components/pages/location_selection_page/LocationSelectionIcons';

type Props = {
  index: number;
  location: {
    address1: string;
    address2?: string | null;
    city: string;
    state: string;
    country: string;
    zipcode: string;
    name?: string | null;
    externalId: string;
    shortUrl?: string | null;
    distanceToCustomer: number;
    phoneNumber?: string | null;
    schedule?: {
      days: string[];
      intervals?: {
        endTime: string;
        startTime: string;
        overnight?: boolean | null;
      }[] | null | undefined;
    }[] | null | undefined
  };
  schedule?: ScheduleType;
  onSelect: (guid?: string) => void;
  selected: boolean;
  deliveryInfo?: DeliveryInfoInput | null;
  diningOptionBehavior: DiningOptionBehavior;
}

export const getAvailableBehaviors = (schedule: ScheduleType, shouldUseDpsApiV2: Boolean) => {
  const behaviors = schedule.schedule.upcomingSchedules
    .filter(schedule => schedule.dailySchedules.length)
    .map(behavior => behavior.behavior) || [];

  if(schedule?.schedule.asapAvailableForDelivery) {
    behaviors.push(DiningOptionBehavior.Delivery);
  }

  if(schedule?.schedule.asapAvailableForTakeout) {
    behaviors.push(DiningOptionBehavior.TakeOut);
  }

  const behaviorSet = new Set([...behaviors]);

  // Comes from delivery-providers API V2
  const deliveryServiceAvailability = schedule?.deliveryServiceAvailability?.available;
  // Comes from delivery-providers API V1
  const availableDeliveryProviders = schedule?.deliveryProviders?.some(provider => provider.enabled && !provider.pending);
  const uses3pd = shouldUseDpsApiV2 ? deliveryServiceAvailability : availableDeliveryProviders;

  // If a location uses 3PD, their delivery schedule is their takeout schedule --
  // if they have takeout availability we can assume they have delivery availability as well
  if(uses3pd && behaviorSet.has(DiningOptionBehavior.TakeOut)) {
    behaviorSet.add(DiningOptionBehavior.Delivery);
  }

  return behaviorSet;
};

const formatter = new Intl.NumberFormat('en-US', { style: 'unit', unit: 'mile', unitDisplay: 'long', maximumFractionDigits: 1 });

const getOptionText = (option: DiningOptionBehavior) => {
  switch(option) {
    case DiningOptionBehavior.Delivery:
      return 'Delivery';
    case DiningOptionBehavior.TakeOut:
      return 'Pickup';
    default:
      return 'now';
  }
};

const getOptionBadge = (option: DiningOptionBehavior, color?: string) => {
  switch(option) {
    case DiningOptionBehavior.Delivery:
      return (
        <div key="delivery" className="optionBadge">
          <DeliveryIcon color={color} /> Delivery
        </div>
      );
    case DiningOptionBehavior.TakeOut:
      return (
        <div key="pickup" className="optionBadge">
          <PickupIcon color={color} /> Pickup
        </div>
      );
    default:
      return null;
  }
};

const LocationOption = (props: Props, ref: React.RefObject<HTMLDivElement>) => {
  const { restaurant, updateLocation, toastProduct } = useRestaurant();
  const { orderPathPattern } = useRestaurantRoutes();
  const { selected, onSelect, location: loc, deliveryInfo, diningOptionBehavior } = props;
  const [scheduleOpen, toggleSchedule] = useReducer(state => !state, false);
  const iconColor = toastProduct === ToastProduct.Sites ? restaurant.meta.textColor : restaurant.config.ooConfig?.colors?.primaryText ?? undefined;
  const { tdsDeliveryProvidersApiV2SitesWeb } = useFlags();

  const isDelivery = diningOptionBehavior === DiningOptionBehavior.Delivery;

  const { data: validDelivery, loading } = useValidateDeliveryDistanceQuery({
    variables: {
      input: {
        deliveryInfo: deliveryInfo || {} as DeliveryInfoInput,
        restaurantGuid: loc.externalId
      }
    },
    ssr: false,
    skip: !deliveryInfo || !isDelivery
  });

  const availableBehaviors = useMemo(
    () => props.schedule
      ? getAvailableBehaviors(
        props.schedule, tdsDeliveryProvidersApiV2SitesWeb
      )
      : new Set<DiningOptionBehavior>(), [props.schedule, tdsDeliveryProvidersApiV2SitesWeb]
  );
  const hasAnyAvailability = !!availableBehaviors?.size;
  const hasBehaviorAvailability = useMemo(() => availableBehaviors?.has(diningOptionBehavior), [availableBehaviors, diningOptionBehavior]);
  const isLocationOpen = useMemo(() =>
    (isDelivery ? props.schedule?.schedule.asapAvailableForDelivery : props.schedule?.schedule.asapAvailableForTakeout) ?? false, [isDelivery, props.schedule]);
  const timeZoneId = useMemo(() => props.schedule?.timeZoneId || Intl.DateTimeFormat().resolvedOptions().timeZone, [props.schedule]);

  const diningBehaviorSchedules = props.schedule?.schedule.upcomingSchedules;
  const diningBehaviorSchedule: UpcomingSchedules | undefined = diningBehaviorSchedules?.filter(schedule => schedule.behavior == diningOptionBehavior)[0];

  const linkedDiningOptionBehavior = hasBehaviorAvailability ?
    diningOptionBehavior :
    Array.from(availableBehaviors).filter(behavior => behavior !== diningOptionBehavior)[0];

  const getHref = useCallback(() => {
    // This is already checked before using getHref, but necessary here to appease typescript
    if(!loc.shortUrl) {
      return '';
    }

    const params = new URLSearchParams();

    const isOrderLink = hasAnyAvailability && linkedDiningOptionBehavior || restaurant.config.isOnlineOrderingOnly;
    const url = isOrderLink ? generatePath(orderPathPattern, { slug: loc.shortUrl }) : `/menu/${loc.shortUrl}`;

    if(isOrderLink) {
      params.set('diningOption', linkedDiningOptionBehavior === DiningOptionBehavior.Delivery ? 'delivery' : 'takeout');
    }

    return `${url}?${params.toString()}`;
  }, [linkedDiningOptionBehavior, hasAnyAvailability, restaurant, loc, orderPathPattern]);

  if(isDelivery && loading && props.index === 0) {
    return props.index === 0 ? <ContextualLoadingSpinner /> : null;
  }

  // If delivery is selected and an option for this restaurant and we're out of range, don't display
  if(isDelivery && (!availableBehaviors?.has(DiningOptionBehavior.Delivery) || !validDelivery?.validateDeliveryLocationV2?.valid)) {
    return null;
  }

  return (
    <div
      ref={ref}
      onClick={() => {
        onSelect(loc.externalId);
      }}
      className={classnames('location', { selected })}>
      <div className="nameDistance">
        <h2 className="name">{loc.name}</h2>
        {loc.distanceToCustomer >= 0 ? <div className="distance">{formatter.format(loc.distanceToCustomer)} away</div> : null}
      </div>
      <div className="locationInfo">
        {loc.phoneNumber ?
          <a className="phoneNumber" href={`tel:${loc.phoneNumber}`}>
            {parsePhoneNumber(loc.phoneNumber, loc.country as CountryCode).formatNational()}
          </a>
          : null}
        <div className="address">
          {loc.address1}{loc.address2 ? `, ${loc.address2}` : null}, {loc.city}, {loc.state} {loc.zipcode}
        </div>
      </div>
      <div key="unavailable">
        {!hasAnyAvailability || !hasBehaviorAvailability ?
          <div className="unavailable">
            <Image src="icons/warning-badge.svg" />
            {hasAnyAvailability ? `${upperFirst(getOptionText(diningOptionBehavior))} is` : 'Delivery and pickup are'} not available at this location
          </div>
          : null}
      </div>
      <div key="storeHours" className="storeHours">
        <button className="hoursToggle" onClick={toggleSchedule} aria-expanded={scheduleOpen}>
          <div className="openTag">
            {getTakingOrdersTillString(isLocationOpen, diningBehaviorSchedule, timeZoneId)}
          </div>
          <Image src="icons/caret-down.svg" className={scheduleOpen ? 'toggled' : ''} />
        </button>
        <dl className={classnames('schedule', { expanded: scheduleOpen })} aria-hidden={!scheduleOpen}>
          {diningBehaviorSchedule?.dailySchedules.map(dailySchedule => {
            const isToday = isTodaysSchedule(dailySchedule, timeZoneId);
            return (
              <div key={dailySchedule.date} className="dayRow">
                <dt className={isLocationOpen && isToday ? 'bold' : ''}>{getDayFromDate(dailySchedule.date)}</dt>
                <dd className="hoursAndTooltip">
                  <span className="hoursWrapper">
                    {dailySchedule.servicePeriods.length ?
                      dailySchedule.servicePeriods.map(servicePeriod => {
                        const isCurrentHours = isLocationOpen && isToday && intervalContainsNow(servicePeriod.startTime, servicePeriod.endTime, timeZoneId);
                        return (
                          <span className={classnames('scheduleHours', isCurrentHours ? 'bold' : '')} key={formatTimeString(servicePeriod.startTime, true)}>
                            {formatTimeString(servicePeriod.startTime, true)} - {formatTimeString(servicePeriod.endTime, true)}
                          </span>);
                      })
                      : 'Closed'}
                  </span>
                  {dailySchedule?.overrideDescription ?
                    <Popover
                      contextKey="scheduleOverridePopover"
                      target={({ open }) =>
                        <div className="infoIcon" onClick={open}><Image src="icons/info.svg" /></div>}>
                      <div className="tooltipContent">{dailySchedule?.overrideDescription}</div>
                    </Popover>
                    : null}
                </dd>
              </div>
            );
          })}
        </dl>
      </div>
      <div className="badges">
        {Array.from(availableBehaviors).sort()
          .map(behavior => getOptionBadge(behavior, iconColor))}
      </div>
      {loc.shortUrl &&
        <ButtonLink
          className="orderButton"
          href={getHref()}
          onClick={() => updateLocation(loc.externalId)}
          variant={ButtonType.Primary}>
          {!hasAnyAvailability || !linkedDiningOptionBehavior ?
            'View Menu'
            : `Order ${getOptionText(linkedDiningOptionBehavior)}`}
        </ButtonLink>}
    </div>
  );
};

export default React.forwardRef(LocationOption);
