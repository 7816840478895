import React from 'react';

import { useEditor } from '@toasttab/sites-components';
import { ContainsEditableProps } from '@toasttab/sites-components/dist/types/components/editor_context/types';

import { PaddingEnum, LoyaltySignupSectionFieldsFragment } from 'src/apollo/sites';

import { getBackgroundColorModule, getSectionPaddingModule } from 'shared/components/common/editor_context/editableUtils';

import { BetterTogetherSection } from 'public/components/default_template/better_together_section/BetterTogetherSection';
import {
  getSuccessHeader,
  LOYALTY_SIGNUP_CONTENT,
  useLoyaltyContext
} from 'public/components/pages/loyalty_page/LoyaltyPage';


type LoyaltyWidgetProps = {
  section: LoyaltySignupSectionFieldsFragment
} & ContainsEditableProps

export const LoyaltySignupSection = ({ editPath, ...props }: LoyaltyWidgetProps) => {
  const {
    onSubmit,
    programDescription,
    useEmail,
    submitState,
    programName
  } = useLoyaltyContext();
  const { useEditableRef } = useEditor();

  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'loyalty signup section',
    actions: ['move-up', 'move-down', 'duplicate', 'delete'],
    schema: {
      fields: [
        getSectionPaddingModule(editPath, PaddingEnum.Medium),
        getBackgroundColorModule(editPath, '#ffffff')
      ]
    },
    path: editPath
  });

  const headerInfo = {
    content: props.section.loyaltySignupHeader || LOYALTY_SIGNUP_CONTENT.defaultHeader,
    editPath: `${editPath}.loyaltySignupHeader`
  };

  const subheaderInfo = {
    content: programDescription,
    editPath: undefined // not editable.
  };

  return (
    <div className={'loyaltySignupSection'}>
      <BetterTogetherSection
        ignoredRef={editableRef}
        isSection={true}
        submitState={submitState}
        onSubmit={onSubmit}
        successLine1={getSuccessHeader(programName)}
        successLine2={LOYALTY_SIGNUP_CONTENT.successLine2}
        errorLine1={LOYALTY_SIGNUP_CONTENT.errorLine1}
        errorLine2={LOYALTY_SIGNUP_CONTENT.errorLine2}
        header={headerInfo}
        subheader={subheaderInfo}
        inputType={useEmail ? 'email' : 'phone'}
        disclaimer={
          <>
            {useEmail
              ? LOYALTY_SIGNUP_CONTENT.emailDisclaimer :
              LOYALTY_SIGNUP_CONTENT.phoneDisclaimer}
          </>
        } />
    </div>
  );
};
