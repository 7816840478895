import {
  Cart,
  Discounts,
  LoyaltyDiscount,
  LoyaltyInquiryResponse,
  LoyaltyRedemption,
  LoyaltyRedemptionType,
  Order,
  Selection
} from 'src/apollo/onlineOrdering';

// Gets all of the loyalty options from the inquiry response and those attached to selections on the check
export const getLoyaltyOptions = (
  inquiryResponse: LoyaltyInquiryResponse,
  order: Order
) => {
  const inquiryRedemptions = inquiryResponse.redemptions;
  // take redemptions from selections and cart first as inquiryRedemptions could have available=false but already applied redemptions
  return ensureUniqueRedemptions([
    ...getRedemptionsFromSelections(order, inquiryRedemptions),
    ...getRedemptionsFromCart(order, inquiryRedemptions),
    ...inquiryRedemptions
  ]).sort((a, b) => (a.name || '').localeCompare(b.name || ''));
};

/**
 * Filter the given redemptionsList to ensure that it only contains unique discounts.
 * Uniqueness is determined by redemptionGuid+referenceId for each discount
 * @param redemptionsList
 */
const ensureUniqueRedemptions = (redemptionsList: LoyaltyRedemption[]) => {
  let seenCombination = new Set();

  return redemptionsList.filter(redemption => {
    // Combine redemptionGuid and referenceId to create a unique key
    let key = `${redemption.redemptionGuid}-${redemption.referenceId}`;

    if(!seenCombination.has(key)) {
      seenCombination.add(key);
      return true;
    }
    return false;
  });
};

const getRedemptionsFromCart = (order: Order | undefined, inquiryRedemptions?: LoyaltyRedemption[]): LoyaltyRedemption[] => {
  return order?.discounts?.loyaltyDiscounts?.map((discount: LoyaltyDiscount) => {
    const inquiryRedemption = inquiryRedemptions?.find(it => it.redemptionGuid === discount.guid && it.referenceId === discount.referenceId);
    return {
      ...inquiryRedemption,
      redemptionAmount: discount.amount,
      redemptionGuid: discount.guid,
      type: discount.type,
      referenceId: discount.referenceId,
      name: discount.name,
      // set redemption from order to be available
      available: true
    };
  }) || [];
};

// Gets redemptions that are currently applied to selections
const getRedemptionsFromSelections = (order: Order | undefined, inquiryRedemptions?: LoyaltyRedemption[]) => {
  let redemptions: LoyaltyRedemption[] = [];
  order?.selections?.forEach((selection: Selection) => {
    selection.appliedDiscounts?.forEach((discount: Discounts) => {
      const inquiryRedemption = inquiryRedemptions?.find(it => it.redemptionGuid === discount.discount?.guid!
        && it.referenceId === discount.loyaltyDetails?.referenceId);
      let loyaltyRedemption: LoyaltyRedemption = {
        ...inquiryRedemption,
        redemptionAmount: discount.discountAmount!,
        redemptionGuid: discount.discount?.guid!,
        type: LoyaltyRedemptionType.ItemRewards,
        referenceId: discount.loyaltyDetails?.referenceId,
        name: discount.name,
        // set redemption from order to be available
        available: true
      };
      if(discount.loyaltyDetails?.vendor === 'INTEGRATION') {
        loyaltyRedemption.type = LoyaltyRedemptionType.Integration;
      }
      redemptions.push(loyaltyRedemption);
    });
  });
  return redemptions;
};

// Returns true if the given redemption is found in the cart
export const isRedemptionInCart = (r: LoyaltyRedemption, c?: Cart | null) => {
  let redemptions: any[] = [];
  if(c !== null && c) {
    if(c.order?.discounts?.loyaltyDiscounts) {
      redemptions = c.order.discounts.loyaltyDiscounts;
    }
    redemptions = [...redemptions, ...getRedemptionsFromSelections(c.order!)];
    return redemptions.find((l: any) => {
      return (l?.guid === r.redemptionGuid || l.redemptionGuid === r.redemptionGuid) &&
        l?.type === r.type &&
        l?.referenceId === r.referenceId;
    });
  }
  return undefined;
};

export const getRedemptionGuid = (r: LoyaltyRedemption) => {
  return r.redemptionGuid;
};

export const getFirstOrThirdParty = (programName: string | null | undefined) => {
  if(programName === 'INTEGRATION') {
    return '3PL';
  } else {
    if(programName) {
      return 'ToastLoyalty';
    } else {
      return 'none';
    }
  }
};
