import React, { useCallback, useState, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { useComponentTimeout } from 'shared/js/hooks/useComponentTimeout';
import useWindowMessage from 'shared/js/hooks/useWindowMessage';

import SaveCardCheckbox from 'public/components/default_template/online_ordering/checkout/payment/SaveCardCheckbox';
import { usePayment } from 'public/components/online_ordering/PaymentContext';

import { resources } from 'config';

export type EncryptedCreditCardData = {
  encryptionKeyId: string;
  encryptedCardDataString: string;
  cardFirst6: string;
  cardLast4: string;
  zipCode: string;
  expMonth: string;
  expYear: string;
};

const IFRAME_ALERT_TIMEOUT_SECS = 5;

type Props = {
  showCheckbox?: boolean;
  onCardChange?: (data: EncryptedCreditCardData | null) => void;
};

const CreditCardForm = ({ showCheckbox, onCardChange }: Props) => {
  const { restaurant, ooRestaurant } = useRestaurant();
  const [height, setHeight] = useState(0);
  const { setSelectedCreditCard, setNewCreditCard } = usePayment();
  const { ooUseTdpCcForm } = useFlags();

  const { setIsLoaded } = useComponentTimeout('CC form', IFRAME_ALERT_TIMEOUT_SECS, ooRestaurant?.name);

  const onChange = useCallback((data: EncryptedCreditCardData | null) => {
    setNewCreditCard(data);
    setSelectedCreditCard({ newCardSelected: true, savedCardGuid: null });
    onCardChange?.(data);
  }, [onCardChange, setSelectedCreditCard, setNewCreditCard]);

  const messageHandlers = useMemo(() => ({
    CC_VALID: (data: EncryptedCreditCardData) => onChange(data),
    CC_INVALID: () => onChange(null),
    IFRAME_HEIGHT: (data: number) => {
      setHeight(data);
      setIsLoaded(true);
    }
  }), [onChange, setIsLoaded]);

  useWindowMessage({
    origin: new URL(resources.checkoutHost).origin,
    messageHandlers
  });

  const queryParams = useMemo(() => {
    const params = [];
    if(restaurant?.meta?.primaryColor) {
      params.push(`color=${encodeURIComponent(restaurant.meta.primaryColor)}`);
    }
    if(restaurant?.externalRef) {
      params.push(`rxRef=${restaurant.externalRef}`);
    }
    if(ooRestaurant?.creditCardConfig.amexAccepted) {
      params.push('amexAccepted=true');
    }
    return params;
  }, [restaurant?.meta?.primaryColor, ooRestaurant?.creditCardConfig, restaurant?.externalRef]);

  const url = useMemo(() => {
    return ooUseTdpCcForm
      ? `${resources.checkoutHost}${ooRestaurant?.shortUrl}/v3/ccform2?${queryParams.join('&')}`
      : `${resources.checkoutHost}${ooRestaurant?.shortUrl}/v3/ccform?${queryParams.join('&')}`;
  }, [ooRestaurant?.shortUrl, ooUseTdpCcForm, queryParams]);

  if(!restaurant || !ooRestaurant) {
    return null;
  }

  return (
    <div className="creditCardForm" data-testid="creditCardForm">
      {height === 0 && <Skeleton width="100%" height="218px" />}
      <iframe
        title="creditCardForm"
        frameBorder="0"
        scrolling="no"
        height={`${height}px`}
        width="100%"
        sandbox="allow-scripts allow-forms allow-same-origin"
        src={url}
        className="fs-exclude" />
      {showCheckbox && <SaveCardCheckbox />}
    </div>
  );
};

export default CreditCardForm;
