import { setCookie, getCookie } from 'react-use-cookie';

import * as Sentry from '@sentry/react';
import { InMemoryCache, IntrospectionFragmentMatcher, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import crossFetch from 'cross-fetch';
import Observable from 'zen-observable';

import { getSource } from 'src/shared/components/common/authentication';
import { PWLESS_ACCESS, PWLESS_REFRESH, PW_ACCESS, REFRESH_COOKIE_EXPIRY_DAYS } from 'src/shared/components/common/authentication/constants';
import { getAuthenticationHeader } from 'src/vendor/do-secundo-guest-authentication';

import { resources } from 'config';

import introspectionQueryData from './fragmentTypes.json';
import { PasswordlessRefreshTokenDocument } from './onlineOrdering';
import ooIntrospectionQueryData from './ooFragmentTypes.json';
import TimeoutLink from './timeoutLink';
import localIntrospectionQueryData from './toastLocalFragmentTypes.json';

const mergedFragments = { __schema: { types: [...introspectionQueryData.__schema.types, ...ooIntrospectionQueryData.__schema.types, ...localIntrospectionQueryData.__schema.types] } };
const cacheSettings = { fragmentMatcher: new IntrospectionFragmentMatcher({ introspectionQueryResultData: mergedFragments }) };

// Observable needed to get promises to work in onError
// https://github.com/apollographql/apollo-link/issues/646
const promiseToObservable = (promise: Promise<any>) => new Observable((subscriber: any) => {
  promise.then(
    value => {
      if(!subscriber.closed) {
        if(value?.data?.passwordlessRefreshToken?.code === 'UNKNOWN_OR_INVALID_REFRESH') {
          subscriber.error(value);
        } else {
          subscriber.next(value);
          subscriber.complete();
        }
      }
    },
    err => subscriber.error(err)
  );
});

const hasAuthError = (graphQLErrors: any) => graphQLErrors && graphQLErrors.findIndex((err: any) => err?.extensions?.code === 'UNAUTHENTICATED') !== -1;
const handleAuthError = (operation: any, forward: any) => promiseToObservable(refreshAuthToken()).flatMap(() => forward(operation));

// @ts-ignore
export const serverErrorLink = onError(({ response, networkError, graphQLErrors, operation, forward }) => {
  if(graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      if(response) {
        // @ts-ignore
        response.errorMessage = message;
      }
      console.error(`[Request error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }
  if(networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
  if(hasAuthError(graphQLErrors)) {
    return handleAuthError(operation, forward);
  }
});

// @ts-ignore
const clientErrorLink = onError(({ networkError, graphQLErrors, operation, forward }) => {
  if(networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
  if(hasAuthError(graphQLErrors)) {
    return handleAuthError(operation, forward);
  }
});

// @ts-ignore
const giaClientErrorLink = onError(({ networkError, graphQLErrors, operation, forward }) => {
  if(networkError) {
    console.error(`[Network error]: ${networkError.message}`, networkError);
  }
  // Client should handle refreshing the token
});

const legacyAuthLink = setContext((_, { headers }) => {
  const pwlessAccessToken = getCookie(PWLESS_ACCESS);
  const pwAccessToken = getCookie(PW_ACCESS);

  const newHeaders: { [key: string]: string } = {};
  if(pwlessAccessToken) {
    newHeaders['Authorization'] = `Bearer ${pwlessAccessToken}`;
  }
  if(pwAccessToken) {
    newHeaders['toast-customer-access'] = pwAccessToken;
  }

  return {
    headers: {
      ...headers,
      ...newHeaders
    }
  };
});

const giaAuthLink = setContext(async (_, { headers: originalHeaders }) => {
  const authHeader = await getAuthenticationHeader();
  const headers = authHeader
    ? { [authHeader.key]: authHeader.value }
    : {};

  return {
    headers: {
      ...originalHeaders,
      ...headers
    }
  };
});

/**
  * Creates an Apollo client. Specifically, clents created using this method
  * will use an auth token fetched from the browser's cookies before every request.
  *
  * @param {object} state - If desired, the cache can be hydrated with a prefetched state.
  * @return {object} An `ApolloClient` instance suited for requests from a browser client.
  */
export const createClient = (
  uri = resources.apiEndpoint,
  state: NormalizedCacheObject | undefined = undefined,
  includeCredentials?: boolean,
  preventBatch?: boolean,
  additionalHeaders?: object,
  withRetry?: boolean,
  withGiaAuth: boolean = false,
  timeout?: number
) => {
  const isSsr = typeof window === 'undefined';
  const cache = new InMemoryCache(cacheSettings);

  // define a customFetch to override cross-fetch to decorate the outbound request
  // with all batched graphQL operations
  const customFetch = (uri: any, options: any) => {
    try {
      // Parse the body to get the operations
      const body = JSON.parse(options.body);
      const operationNames = body
        .map((op: { operationName: any; }) => op.operationName || 'UnnamedOperation')
        .sort() // sort these operations alphabetically in ascending order
        .join(',');

      // Modify the request headers to include the operation names for every graphQL operation (query/mutation)
      options.headers = {
        ...options.headers,
        'Toast-GraphQL-Operation': operationNames
      };
    } catch(error) {
      // if this decoration fails for ANY reason make sure that the request is never blocked, just move along
      Sentry.captureException(`ERROR: an error occurred decorating apollo request with headers ${error}`);
    }
    // Now call cross-fetch with the modified options
    return crossFetch(uri, options);
  };

  const linkOptions = {
    fetch: customFetch,
    uri,
    credentials: includeCredentials ? 'include' : 'none',
    headers: additionalHeaders || undefined
  };

  const httpLink = preventBatch ?
    new HttpLink(linkOptions) :
    new BatchHttpLink(linkOptions);

  const configuredClientErrorLink = withGiaAuth
    ? giaClientErrorLink
    : clientErrorLink;

  const errorLink = isSsr
    ? serverErrorLink
    : configuredClientErrorLink;

  const links = [errorLink, httpLink];
  const retryLink = withRetry ?
    ApolloLink.from([
      new RetryLink({
        delay: { initial: 200, max: 1000, jitter: true },
        attempts: {
          max: 4,
          retryIf: (error, _operation) => {
            return error?.message === 'Network request failed';
          }
        }
      }),
      ...links
    ]) :
    ApolloLink.from(links);

  const authLink = withGiaAuth ? giaAuthLink : legacyAuthLink;
  let authedLink = includeCredentials
    ? authLink.concat(retryLink)
    : retryLink;

  const timeoutLink = new TimeoutLink(timeout);
  authedLink = timeoutLink.concat(authedLink);

  return new ApolloClient({
    ssrMode: isSsr,
    link: authedLink,
    cache: state ? cache.restore(state) : cache,
    name: process.env.toast_svc_name || 'sites-web-client',
    version: process.env.toast_service_revision || process.env.VERSION || 'unknown'
  });
};

const authRefreshClient = createClient(resources.ooProxyHost, undefined, true, true, undefined, false, false, resources.clientQueryTimeoutMs);
const refreshAuthToken = async () => {
  const currRefresh = getCookie(PWLESS_REFRESH);

  if(!currRefresh) {
    return null;
  }

  const result = await authRefreshClient.mutate({
    mutation: PasswordlessRefreshTokenDocument,
    // refreshToken is populated by the server
    variables: { input: { refreshToken: currRefresh, source: getSource() } }
  });

  if(result.data?.passwordlessRefreshToken?.__typename === 'PasswordlessTokenResponse') {
    setCookie(PWLESS_ACCESS, result.data.passwordlessRefreshToken.accessToken);
    setCookie(PWLESS_REFRESH, result.data.passwordlessRefreshToken.refreshToken, { days: REFRESH_COOKIE_EXPIRY_DAYS });
  }

  return result;
};
