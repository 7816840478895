import { escapeRegExp } from 'lodash';

import type { RefMenu, RefMenuGroup } from 'public/components/default_template/menu_nav/MenuNav';
import type { MenuItem } from 'public/components/default_template/menu_section/MenuSection';

// see https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
export function removeAccents(text: string): string {
  // double check that input is string because sometimes react-highlight-words will pass in Regex
  if(typeof text === 'string') {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  } else {
    return text;
  }
}

function isNonNull<T>(item: T | null): item is T {
  if(item === null) {
    return false;
  } {
    return true;
  }
}

export function getSearchRegExp(searchString: string): RegExp {
  const regex = new RegExp('\\b' + escapeRegExp(searchString), 'i');
  return regex;
}

// The beginning of the search string must match with the beginning of a word in the target string in order for there to be a match
// case insensitive
function searchMatchesByStartOfWord(targetString: string | null | undefined, searchString: string): boolean {
  if(!targetString) {
    return false;
  }

  const regex = getSearchRegExp(removeAccents(searchString));
  return regex.test(removeAccents(targetString));
}

// Include items that match by name or description
function filterItem(item: MenuItem, searchString: string): MenuItem | null {
  if(searchMatchesByStartOfWord(item.name, searchString)) {
    return item;
  } else if(searchMatchesByStartOfWord(item.description, searchString)) {
    return item;
  } else {
    return null;
  }
}

export function filterItems(items: MenuItem[], searchString: string): MenuItem[] {
  return items.map(item => filterItem(item, searchString)).filter(isNonNull);
}

// Include all items when when the search string matches the menu group name
function filterMenuGroup(menuGroup: RefMenuGroup, searchString: string): RefMenuGroup | null {
  if(searchMatchesByStartOfWord(menuGroup.name, searchString)) {
    return menuGroup;
  }

  const filteredItems = filterItems(menuGroup.items, searchString);

  if(filteredItems.length === 0) {
    return null;
  }

  return {
    ...menuGroup,
    items: filteredItems
  };
}

function filterMenuGroups(menuGroups: RefMenuGroup[], searchString: string): RefMenuGroup[] {
  return menuGroups.map(group => filterMenuGroup(group, searchString)).filter(isNonNull);
}


// Include all menu groups and items when when the search string matches the menu name
function filterMenu(menu: RefMenu, searchString: string): RefMenu | null {
  if(searchMatchesByStartOfWord(menu.name, searchString.toLowerCase())) {
    return menu;
  }

  const filteredGroups = filterMenuGroups(menu.groups, searchString);

  if(filteredGroups.length === 0) {
    return null;
  }

  return {
    ...menu,
    groups: filteredGroups
  };
}

export function filterMenus(menus: RefMenu[], searchString: string): RefMenu[] {
  return menus.map(menu => filterMenu(menu, searchString)).filter(isNonNull);
}
