import isEmpty from 'lodash/isEmpty';

import { DeliveryInfoInput } from 'src/apollo/onlineOrdering';

import { Location, DeliveryInfo } from 'public/components/online_ordering/types';

export const normalizeGoogleAddress = (addressFromGoogle: google.maps.places.PlaceResult) => {
  const normalizedAddress = {} as DeliveryInfoInput;

  if(addressFromGoogle.address_components) {
    for(let component of addressFromGoogle?.address_components || []) {
      for(let componentType of component.types) {
        if(normalizedAddress) {
          if(componentType === 'street_number') {
            normalizedAddress.address1 = normalizedAddress.address1 ? component.long_name + ' ' + normalizedAddress.address1 : component.long_name;
            break;
          } else if(componentType === 'route' || componentType === 'street_address' || componentType === 'premise') {
            normalizedAddress.address1 = normalizedAddress.address1 ? normalizedAddress.address1 + ' ' + component.short_name : component.short_name;
            break;
          } else if(componentType === 'subpremise') {
            normalizedAddress.address2 = `#${component.long_name}`;
            break;
          } else if(
            componentType === 'locality'
            || componentType === 'neighborhood' && !normalizedAddress.city
            || componentType === 'sublocality_level_1' && !normalizedAddress.city
            || componentType === 'administrative_area_level_3' && !normalizedAddress.city
          ) {
            // locality should be given priority, so always overwrite other data that may have been put in the city.
            // https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
            // Brooklyn and other parts of New York City do not include the city as part of the address. Instead, they use sublocality_level_1.
            // Based on querying the Toast Local database, some Rxs dont have a locality or sublocality_level_1, but do have a city in the
            // administrative_area_level_3 field.
            normalizedAddress.city = component.long_name;
            break;
          } else if(componentType === 'administrative_area_level_1') {
            normalizedAddress.state = component.short_name;
            // normalises administrative area to be alligned with state, this addition allows us to enable OO delivery for Canadian customers
            // this will be updated with greater support for international in the coming weeks
            normalizedAddress.administrativeArea = component.short_name;
            break;
          } else if(componentType === 'postal_code') {
            normalizedAddress.zipCode = component.short_name;
            break;
          } else if(componentType === 'country') {
            normalizedAddress.country = component.short_name;
            break;
          }
        }
      }
    }
  }

  if(normalizedAddress && addressFromGoogle.geometry?.location) {
    normalizedAddress.latitude = addressFromGoogle.geometry.location.lat();
    normalizedAddress.longitude = addressFromGoogle.geometry.location.lng();
  }

  return isEmpty(normalizedAddress) ? null : normalizedAddress;
};

export const formatLocationAddress = (address: Location, includeZip: boolean = true) =>
  address ? `${address.address1}${address.address2 ? `, ${address.address2}` : ''}, ${address.city}, ${address.state}${includeZip ? ` ${address.zip}` : ''}` : '';

export const formatDeliveryAddress = (address: DeliveryInfo, full?: boolean) =>
  address ? `${address.address1}${address.address2 ? `, ${address.address2}` : ''}${full ? `, ${address.city}, ${address.state} ${address.zipCode}` : ''}` : '';
