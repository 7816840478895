import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import classnames from 'classnames';
import fromUnixTime from 'date-fns/fromUnixTime';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import FormattedPrice from 'src/shared/components/common/price/FormattedPrice';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';
import { ShowForUS } from 'src/shared/components/common/show_for_us/ShowForUS';
import { getRestaurantCountryCode } from 'src/shared/js/phoneUtils';

import Image from 'shared/components/common/Image';
import Link from 'shared/components/common/link';
import PhoneLink from 'shared/components/common/link/PhoneLink';
import { toLocalDateTime } from 'shared/js/timeUtils';

import EmptyCart from 'public/components/default_template/online_ordering/cart/EmptyCart';
import { calculateDiscountsTotal } from 'public/components/default_template/online_ordering/cart/cartUtils';
import LoyaltySection from 'public/components/default_template/online_ordering/confirmation/LoyaltySection';
import OrderItems, { OrderPrices } from 'public/components/default_template/online_ordering/order/OrderItems';
import { useCompletedOrder } from 'public/components/online_ordering/OrderContext';
import { formatDeliveryAddress, formatLocationAddress } from 'public/components/online_ordering/addressUtils';
import { calculateSubtotal } from 'public/components/online_ordering/types';

import { PackagingSelections } from './PackagingSelections/PackagingSelections';
import TakeoutCTA from './TakeoutCTA/TakeoutCTA';

const OrderConfirmation = () => {
  const { completedOrder, loadingCompletedOrder } = useCompletedOrder();

  const orderTotal = completedOrder ? completedOrder.totalV2 - (completedOrder.giftCard?.appliedBalance || 0) : 0;
  const subtotal = useMemo(() => calculateSubtotal(completedOrder), [completedOrder]);
  const { restaurant, ooRestaurant } = useRestaurant();
  const { ooTtoCtaConfirmationV1 } = useFlags();
  const i18nCountryCode = getRestaurantCountryCode(ooRestaurant?.i18n?.country);

  if(loadingCompletedOrder) {
    return (
      <div className="orderConfirmation checkoutForm">
        <section className="checkoutSection currentSection">
          <Skeleton width="100%" height="500px" />
        </section>
      </div>
    );
  }

  if(!completedOrder) {
    return <EmptyCart />;
  }

  const isOOBasic = restaurant.config.isOnlineOrderingOnly && restaurant.config.hasFullCustomization === false;
  const shouldShowCTA = ooTtoCtaConfirmationV1 && isOOBasic && ooRestaurant?.consumerCTAEnabled;

  const destination = encodeURIComponent([
    completedOrder.restaurant.location.address1,
    completedOrder.restaurant.location.address2 || '',
    completedOrder.restaurant.location.city,
    completedOrder.restaurant.location.state,
    completedOrder.restaurant.location.zip
  ].join(' '));
  const directionsLink = `https://www.google.com/maps/dir/?api=1&destination=${destination}`;

  return (
    <div className="orderConfirmation checkoutForm">
      <section className="checkoutSection currentSection flat">
        <div className="checkoutSectionHeader">
          <div>Thanks for your order{completedOrder.customerV2?.firstName && `, ${completedOrder.customerV2?.firstName}`}!</div>
          <div className="checkNumber">Order #{completedOrder.checkNumber}</div>
        </div>
        <div className="fixedSection">
          <div>An email receipt has been sent to {completedOrder.customerV2?.email || 'you'}</div>
          {completedOrder.restaurant.loyaltyConfig?.loyaltySignupEnabled ? <LoyaltySection /> : <hr />}
          <div className="subHeader">{completedOrder.restaurant.name}</div>
          <div className="address">{formatLocationAddress(completedOrder.restaurant.location)}</div>
          <PhoneLink className="linkText phone" phoneNumber={completedOrder.restaurant.location.phone} countryCode={i18nCountryCode} />
          <br />
          <br />
          <div className="sectionRows">
            {completedOrder.estimatedFulfillmentDate &&
              <div className="sectionRow">
                <div className="icon">
                  <Image alt="Order time" src="icons/clock-gray.svg" />
                </div>
                Your order will be {completedOrder.diningOptionBehavior === DiningOptionBehavior.Delivery ? 'delivered' : 'ready'}{' '}
                {toLocalDateTime(fromUnixTime(completedOrder.estimatedFulfillmentDate / 1000))}
              </div>}
            {completedOrder.diningOptionBehavior === DiningOptionBehavior.TakeOut &&
              <>
                <div className="sectionRow noMargin">
                  <div className="icon">
                    <Image alt="Location" src="icons/location.svg" />
                  </div>
                  {completedOrder.restaurant.location.address1}{completedOrder.restaurant.location.address2 ? `, ${completedOrder.restaurant.location.address2}` : ''}
                </div>
                <div className="sectionRow directions">
                  <div className="icon"></div>
                  <Link href={directionsLink}>
                    Get directions
                  </Link>
                </div>
              </>}
            <div className={classnames('sectionRow', completedOrder.guestCommunication && 'noMargin')}>
              <div className="icon">
                {completedOrder.curbsidePickup?.selected || completedOrder.curbsidePickupV2 ?
                  <Image alt="Curbside pickup details" src="icons/curbside-gray.svg" /> :
                  <Image alt="Order type" src={completedOrder.diningOptionBehavior === DiningOptionBehavior.Delivery ? 'icons/delivery.svg' : 'icons/pickup.svg'} />}
              </div>
              {completedOrder.curbsidePickup?.selected || completedOrder.curbsidePickupV2 ?
                'Curbside' :
                completedOrder.diningOptionBehavior === DiningOptionBehavior.Delivery ?
                  `Delivery${completedOrder.deliveryInfo ? ` to ${formatDeliveryAddress(completedOrder.deliveryInfo)}` : ''}` :
                  'Pickup'}
            </div>
            {completedOrder.guestCommunication &&
              <div className="sectionRow instructions">
                <div className="icon"></div>{completedOrder.guestCommunication}
              </div>}
          </div>
          <div className="subHeader">Order summary</div>
          <div className="cartOrder">
            <div className="cartData">
              <OrderItems loading={loadingCompletedOrder} selections={completedOrder.selections} />
              {Boolean(completedOrder.packagingSelections.length) &&
                <PackagingSelections packaging={completedOrder.packagingSelections || []} />}
              <OrderPrices
                loading={loadingCompletedOrder}
                subtotal={subtotal}
                discountsTotal={calculateDiscountsTotal(completedOrder?.discounts?.globalReward?.amount || 0, completedOrder.discountsTotal)}
                diningOptionBehavior={completedOrder.diningOptionBehavior}
                deliveryFee={completedOrder.deliveryServiceCharges}
                gratuityServiceCharges={completedOrder.gratuityServiceCharges}
                processingServiceCharges={completedOrder.processingServiceCharges}
                nonDeliveryNonGratuityNonUbpServiceCharges={completedOrder.nonDeliveryNonGratuityNonUbpServiceCharges}
                tax={completedOrder.taxV2}
                tip={completedOrder.tip}
                fundraisingAmount={completedOrder.fundraisingTotal}
                giftCardAppliedAmount={completedOrder.giftCard?.appliedBalance} />
              <div className="prices">
                <div className="cart-flex-row">
                  <div className="totalPrice">Total</div>
                  <div className="totalPrice"><FormattedPrice value={orderTotal} /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {shouldShowCTA && <ShowForUS><TakeoutCTA orderGuid={completedOrder.guid} /></ShowForUS>}
    </div>
  );
};

export default OrderConfirmation;
