import React, { useCallback, useContext, useMemo, useState } from 'react';

import debounce from 'lodash/debounce';

import { MenuSearchMode } from 'src/apollo/sites';
import { useFlags } from 'src/shared/components/common/feature_flags/useFlags';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { getSearchRegExp } from './searchFilterUtils';

type SearchContextType = {
  searchString: string;
  setSearchString: (value: string) => void;
  clearSearchString: () => void;
  canUseSearch: boolean;
  searchRegExp: RegExp;
}

export const defaultSearchContext = {
  searchString: '',
  setSearchString: () => {},
  clearSearchString: () => {},
  canUseSearch: false,
  searchRegExp: /^\b$/ // this regex for default case never matches anything
} as const;

const SearchContext = React.createContext<SearchContextType>(defaultSearchContext);


export const MenuSearchContextProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { restaurant } = useRestaurant();
  const searchEnabledViaConfig = restaurant.config.ooConfig?.format?.menuSearchMode !== MenuSearchMode.Hidden;

  const flags = useFlags();
  const ldSdkFlag = flags['nvrCanSearchOoOrderPage'] || flags['nvr-can-search-oo-order-page'];

  // when in the sites editor, the LaunchDarkly client is not set up with the correct context,
  // so instead we read the feature flag directly from Banquet props.
  // TODO: @phillarie-toast remove this logic once feature has been rolled out.
  // @ts-ignore
  const banquetFlag = Boolean(typeof window !== 'undefined' && window?.__TOAST_BANQUET_INITIAL_DATA__?.featureFlags?.['nvr-can-search-oo-order-page']);
  const searchFlagEnabled = ldSdkFlag || banquetFlag;

  const canUseSearch = searchFlagEnabled && searchEnabledViaConfig;

  const [searchString, setSearchString] = useState('');

  const debouncedSetSearchString = useMemo(
    () => debounce((value: string) => setSearchString(value), 500),
    [setSearchString]
  );

  // sets the search string to '' without debounce
  const clearSearchString = useCallback(() => {
    setSearchString('');
    // also clear the search string with the debounce method to override any currenlty waiting debounce calls
    debouncedSetSearchString('');
  }, [setSearchString, debouncedSetSearchString]);

  const contextData = useMemo(() => ({
    searchString,
    setSearchString: debouncedSetSearchString,
    clearSearchString,
    canUseSearch,
    searchRegExp: getSearchRegExp(searchString)
  }), [searchString, debouncedSetSearchString, clearSearchString, canUseSearch]);

  return (
    <SearchContext.Provider value={contextData}>
      {children}
    </SearchContext.Provider>
  );
};

export const useMenuSearchContext = () => {
  return useContext(SearchContext);
};
